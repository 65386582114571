<template>
  <Data
    :showMenu="false"
    :shadow="true"
    :contractName="true"
    :showBackNavigation="true"
    :disableMenu="true"
    :closeAndSave="true"
    @close="closeAndSave"
    @onNavigationBack="navigateBackHandler"
  >
    <v-container
      class="contract-detail__wrapper px-0"
      :class="{ 'pt-6': contract.type === 'form' }"
      fluid
    >
      <v-card
        class="mt-5"
        elevation="2"
      >
        <v-row
          align="stretch"
          justify="space-between"
          no-gutters
        >
          <v-col
            class="col-md-3"
            style="background-color: #f2f6fc"
          >
            <drop-list
              :style="isHighlited ? 'border: 1px solid #5f23a0' : 'border: none'"
              :items="availableDocuments || []"
              :column="true"
              accepts-type="documents"
              @reorder="reorder"
              @insert="insertDocument"
              mode="cut"
            >
              <drag
                v-for="attachment in availableDocuments"
                :key="attachment.id"
                :data="attachment"
                :disabled="!isContractOwner"
                type="documents"
                @dragstart="highlight = true"
                @dragend="highlight = false"
                @cut="remove(availableDocuments, attachment)"
              >
                <CreateContractDocumentTab
                  :contract="attachment"
                  :contracts="contract.attachments || []"
                >
                  <div
                    class="content-box mt-0 pb-0"
                    :style="{
                      marginTop: contract.attachments && contract.attachments.length > 0 ? '0' : null,
                    }"
                  >
                    <div
                      v-if="contract.type === 'form'"
                      class="ml-2 mr-1 pb-2"
                      style="border-bottom: 1px solid #d8d8d8"
                    >
                      <v-row
                        v-if="contentType == '' && done != total && loaded"
                        align="center"
                        justify="start"
                        no-gutters
                      >
                        <v-col
                          cols="12"
                          class="py-1"
                        >
                          <v-checkbox
                            v-model="autoFocusNextInput"
                            class="mt-0"
                            hide-details
                          >
                            <template v-slot:label>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <span v-on="on">
                                    {{ $t('contract.automatic_completion') }}
                                  </span>
                                </template>

                                {{ $t('contract.automatic_completion_description') }}
                              </v-tooltip>
                            </template>
                          </v-checkbox>
                        </v-col>

                        <v-col
                          v-if="isKatastrEnabled"
                          cols="12"
                          class="py-1"
                        >
                          <v-hover v-slot="{ hover }">
                            <v-btn
                              color="primary"
                              :class="{ 'text-decoration-underline': hover }"
                              block
                              text
                              @click="prefillData"
                              >{{ $t('contract.prefill.from_katastr') }}</v-btn
                            >
                          </v-hover>
                        </v-col>

                        <v-col
                          v-if="isPrefillFromContactsEnabled"
                          cols="12"
                          class="py-1"
                        >
                          <v-hover v-slot="{ hover }">
                            <v-btn
                              color="primary"
                              :class="{ 'text-decoration-underline': hover }"
                              block
                              text
                              @click="prefillData"
                              >{{ $t('contract.prefill.from_contacts') }}</v-btn
                            >
                          </v-hover>
                        </v-col>
                      </v-row>
                    </div>

                    <div
                      class="content-box__data"
                      v-bind:class="{
                        'content-box__data--full': contract.type === 'doc' || contract.type === 'wysiwyg',
                      }"
                    >
                      <ContentPanel
                        v-if="
                          contract.type !== 'form' &&
                          (!areSigningContractRolesConsistent || isEditSignaturesForced) &&
                          loaded
                        "
                      >
                        <ContentSigns
                          :signatures="contractSignatures"
                          :activeSignPlacement="activeSignPlacement"
                          :isEditSignaturesForced="isEditSignaturesForced && areSigningContractRolesConsistent"
                          @continueToSignButtons="isEditSignaturesForced = false"
                          @autoPlaceSign="autoPlaceSignature($event)"
                          @placeSign="placeSign($event)"
                          @openSignaturePositionsModal="openSignaturePositionsModal"
                          @setReadyToSend="setReadyToSend"
                        />
                      </ContentPanel>

                      <ContentPanel v-else-if="contract.type !== 'form' && loaded">
                        <ContentFinishedFull
                          :areContractRolesConsistent="areContractRolesConsistent"
                          :contractSignatures="contractSignatures"
                          contentClass="content-finished-full"
                          :confirmBeforeSend="confirmBeforeSend"
                          @goBackToEdit="isEditSignaturesForced = true"
                          @addAttachment="addAttachment()"
                        />
                      </ContentPanel>

                      <div
                        v-else-if="
                          contentType == '' &&
                          doneWithoutOptional == totalWithoutOptional &&
                          loaded &&
                          contract.type === 'form'
                        "
                        class="content-box--form"
                      >
                        <div class="content-box__header">
                          <div v-if="contract.template && showFinish && arePartiesFilled()">
                            <ContentFinishedFull
                              contentClass="content-finished"
                              :areContractRolesConsistent="areContractRolesConsistent"
                              @addAttachment="addAttachment()"
                            />
                          </div>

                          <div v-else>
                            <ContentFinishedFull
                              contentClass="content-finished"
                              :areContractRolesConsistent="areContractRolesConsistent"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="content-box__data__ico">
                        <IcoEnvelopeWrap />
                      </div>

                      <template v-if="loaded">
                        <div
                          v-if="contract && contract.type == 'form'"
                          id="contract-template-content"
                          class="contract-creation"
                          style="font-size: 16px"
                          v-html="contract.template.raw_content"
                        ></div>

                        <Drop
                          v-else
                          accepts-type="signatures"
                          @drop="dragend"
                        >
                          <template v-slot:drag-image="{ data }">
                            <div
                              :class="`
                            sign-placement__preview
                            ${isDefaultSignHeaderIgnored ? 'sign-placement__preview__no_header' : ''}
                          `"
                            >
                              <div class="sign-placement__header sign-placement__header-move-top">
                                <span class="sign-placement__date">
                                  {{ $t('signature.header.placeholder') }}
                                </span>
                              </div>

                              <div class="sign-placement__inner">
                                <span class="sign-placement__title">
                                  <div class="signature-img signature-img--empty"></div>
                                </span>

                                <span class="sign-placement__name">{{ data.firstname }} {{ data.lastname }}</span>
                              </div>

                              <div class="sign-placement__dots"></div>
                            </div>
                          </template>

                          <div
                            class="showImages-wrapper"
                            style="position: relative"
                          >
                            <div
                              style="position: relative"
                              class="showImages"
                            >
                              <div
                                v-for="(page, pageIndex) in showReducedVersion ? [currentPage] : pages"
                                :key="pageIndex"
                                class="page"
                                :class="{ 'page--last': showReducedVersion ? true : pageIndex === numberOfPages - 1 }"
                                :data-page-index="showReducedVersion ? activePageIndex : pageIndex"
                                @click="focusedSignatures = []"
                              >
                                <pdf
                                  :id="showReducedVersion ? activePageIndex : pageIndex"
                                  :src="documentLink"
                                  :page="page.pageNumber"
                                  style="z-index: -1"
                                >
                                  <template v-slot:loading>
                                    <DocumentPageLoader />
                                  </template>
                                </pdf>

                                <SignaturePlaceholder
                                  v-for="(signature, index) in signaturesPerPage[
                                    showReducedVersion ? activePageIndex : pageIndex
                                  ]"
                                  v-model="signaturesPerPage[showReducedVersion ? activePageIndex : pageIndex][index]"
                                  :key="signature.uniqueSignId"
                                  :is-default-disabled-header="isDefaultSignHeaderIgnored"
                                  :is-default-disabled-footer="!isDefaultSignFooterIgnored"
                                  :updated-placeholder-width="updatedPlaceholderWidth || workspacePlaceholderWidth"
                                  :workspacePlaceholderWidth="workspacePlaceholderWidth"
                                  :isInconsistentPlaceholder="isInconsistentPlaceholder(signature)"
                                  @stopSignatureDrag="stopSignatureDrag($event)"
                                  @placeAnotherSign="placeAnotherSign(signature)"
                                  @blurSignature="blurSignature(signature.uniqueSignId)"
                                  @deleteSignature="deleteSignature(signature)"
                                  @setDraggingSignature="startSignatureDrag(signature, $event)"
                                  @setNewWidth="setPlaceholderWidth(signature, $event)"
                                  @on-resize-placeholder="updatedPlaceholderWidth = $event"
                                  @on-changes-done="savePositionsInBackend"
                                />
                              </div>

                              <div
                                v-if="showReducedVersion"
                                class="document-pages__bottom-buttons"
                              >
                                <v-pagination
                                  :value="currentPageNumber"
                                  :length="pages.length"
                                  :total-visible="5"
                                  @input="setCurrentPageNumber"
                                ></v-pagination>
                              </div>
                            </div>
                          </div>
                        </Drop>
                      </template>

                      <template v-else>
                        <div>
                          <Loader
                            class="register-loader"
                            style="text-align: center; padding-left: 0"
                          />
                        </div>
                      </template>
                    </div>

                    <div
                      v-if="contract.type === 'form' && loaded"
                      class="content-box__controll"
                      :style="[
                        contentType !== '' || (contentType === '' && done === total && !closedSuccessBox && !showFinish)
                          ? { display: 'block' }
                          : { display: 'none' },
                      ]"
                    >
                      <template v-if="contentType == '' && done != total && loaded">
                        <Note class="content-box__note-first">
                          <p>{{ $t('contract.click_on_highlighted_text') }}</p>
                        </Note>

                        <div class="content-box__autofocus-toggle">
                          <Checkbox
                            class="workspace-input"
                            v-model="autoFocusNextInput"
                            @click="toggleAutoFocusNextInput()"
                            :label="$t('contract.automatic_completion')"
                          />

                          <span
                            class="content-box__autofocus-toggle-description"
                            :title="$t('contract.automatic_completion_description')"
                            ><IcoInfo
                          /></span>

                          <div
                            v-if="isKatastrEnabled"
                            class="fill-from-registry-button"
                            @click="prefillData"
                          >
                            {{ $t('contract.prefill.from_katastr') }}
                          </div>

                          <div
                            v-if="isPrefillFromContactsEnabled"
                            class="fill-from-registry-button"
                            @click="prefillData"
                          >
                            {{ $t('contract.prefill.from_contacts') }}
                          </div>
                        </div>
                      </template>

                      <div class="content-box__controll--boxwrap">
                        <div
                          v-if="contentType === 'options'"
                          class="content-box__controll-wrap"
                          :style="{ top: contentTop + 'px' }"
                        >
                          <v-toolbar
                            dense
                            flat
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              @click="closeOpenedContentType"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <form
                            @submit.prevent="saveContentType"
                            @reset.prevent="saveContentType"
                          >
                            <div>
                              <span class="controll-title">{{ activeElementTitle }}</span>
                            </div>

                            <div v-if="activeElementDescription">
                              <span class="controll-desc">{{ activeElementDescription }}</span>
                            </div>

                            <div v-if="contentOptions && contentOptions.length">
                              <Radio
                                v-for="(option, index) in contentOptions"
                                :key="index"
                                :value="contentOptionsChoice"
                                :labelValue="option.value"
                                :label="option.label"
                                @change="changeRadioValue"
                              />
                            </div>

                            <div class="content-box__controll__buttons">
                              <Button
                                buttonType="submit"
                                :disabled="activeContent == ''"
                                type="primary-small"
                                >{{ $t('general.save') }}</Button
                              >
                            </div>

                            <div
                              class="content-box__controll__buttons"
                              v-if="isActiveElementOptional()"
                            >
                              <Button
                                buttonType="reset"
                                type="primary-small"
                                >{{ $t('contract.skip') }}</Button
                              >
                            </div>
                          </form>
                        </div>

                        <div
                          v-if="contentTop && contentType === 'textarea'"
                          class="content-box__controll-wrap"
                          :style="{ top: contentTop + 'px' }"
                        >
                          <v-toolbar
                            dense
                            flat
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              @click="closeOpenedContentType"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <form
                            @submit.prevent="saveContentType"
                            @reset.prevent="saveContentType"
                          >
                            <div>
                              <span class="controll-title">{{ activeElementTitle }}</span>
                            </div>

                            <div v-if="activeElementDescription">
                              <span class="controll-desc">{{ activeElementDescription }}</span>
                            </div>

                            <div class="wysiwyg-editor">
                              <editor-menu-bar
                                class="wysiwyg-editor-menu"
                                :editor="activeContent"
                                v-slot="{ commands, isActive }"
                              >
                                <div>
                                  <button
                                    :class="{ 'is-active': isActive.bold() }"
                                    @click.prevent="commands.bold"
                                    :style="{ fontWeight: 'bold' }"
                                  >
                                    B
                                  </button>
                                  <button
                                    :class="{ 'is-active': isActive.italic() }"
                                    @click.prevent="commands.italic"
                                    :style="{ fontStyle: 'italic' }"
                                  >
                                    I
                                  </button>
                                  <button
                                    :class="{ 'is-active': isActive.underline() }"
                                    @click.prevent="commands.underline"
                                    :style="{ textDecoration: 'underline' }"
                                  >
                                    U
                                  </button>
                                </div>
                              </editor-menu-bar>

                              <editor-content
                                class="wysiwyg-editor-content"
                                :placeholder="activeElementPlaceholder"
                                :editor="activeContent"
                              />
                            </div>

                            <div class="content-box__controll__buttons">
                              <Button
                                buttonType="submit"
                                type="primary-small"
                                >{{ $t('general.save') }}</Button
                              >
                            </div>

                            <div
                              class="content-box__controll__buttons"
                              v-if="isActiveElementOptional()"
                            >
                              <Button
                                buttonType="reset"
                                type="primary-small"
                                >{{ $t('contract.skip') }}</Button
                              >
                            </div>

                            <div>
                              <small class="controll-desc">{{ $t('contract.press_alt_enter') }}</small>
                            </div>
                          </form>
                        </div>

                        <div
                          v-if="contentTop == 0 && contentType == 'textarea'"
                          class="content-box__controll-wrap"
                          :style="{ bottom: +'10px' }"
                        >
                          <v-toolbar
                            dense
                            flat
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              @click="closeOpenedContentType"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <form
                            @submit.prevent="saveContentType"
                            @reset.prevent="saveContentType"
                          >
                            <div>
                              <span class="controll-title">{{ activeElementTitle }}</span>
                            </div>

                            <div v-if="activeElementDescription">
                              <span class="controll-desc">{{ activeElementDescription }}</span>
                            </div>

                            <div class="wysiwyg-editor">
                              <editor-menu-bar
                                class="wysiwyg-editor-menu"
                                :editor="activeContent"
                                v-slot="{ commands, isActive }"
                              >
                                <div>
                                  <button
                                    :class="{ 'is-active': isActive.bold() }"
                                    @click.prevent="commands.bold"
                                    :style="{ fontWeight: 'bold' }"
                                  >
                                    B
                                  </button>
                                  <button
                                    :class="{ 'is-active': isActive.italic() }"
                                    @click.prevent="commands.italic"
                                    :style="{ fontStyle: 'italic' }"
                                  >
                                    I
                                  </button>
                                  <button
                                    :class="{ 'is-active': isActive.underline() }"
                                    @click.prevent="commands.underline"
                                    :style="{ textDecoration: 'underline' }"
                                  >
                                    U
                                  </button>
                                </div>
                              </editor-menu-bar>

                              <editor-content
                                class="wysiwyg-editor-content"
                                :placeholder="activeElementPlaceholder"
                                :editor="activeContent"
                              />
                            </div>

                            <div class="content-box__controll__buttons">
                              <Button
                                buttonType="submit"
                                type="primary-small"
                                >{{ $t('general.save') }}</Button
                              >
                            </div>

                            <div
                              class="content-box__controll__buttons"
                              v-if="isActiveElementOptional()"
                            >
                              <Button type="primary-small">{{ $t('contract.skip') }}</Button>
                            </div>
                          </form>
                        </div>

                        <div
                          v-if="contentTop && contentType == 'date'"
                          class="content-box__controll-wrap"
                          :style="{ top: contentTop + 'px' }"
                        >
                          <v-toolbar
                            dense
                            flat
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              @click="closeOpenedContentType"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <form
                            @submit.prevent="saveContentType"
                            @reset.prevent="saveContentType"
                          >
                            <div>
                              <span class="controll-title">{{ activeElementTitle }}</span>
                            </div>

                            <div v-if="activeElementDescription">
                              <span class="controll-desc">{{ activeElementDescription }}</span>
                            </div>

                            <div>
                              <SDatePicker
                                v-model="choiceDate"
                                :label="$t('contract.date')"
                                :placeholder="activeElementPlaceholder"
                              />
                            </div>

                            <div class="content-box__controll__buttons">
                              <Button
                                buttonType="submit"
                                type="primary-small"
                                >{{ $t('general.save') }}</Button
                              >
                            </div>

                            <div
                              class="content-box__controll__buttons"
                              v-if="isActiveElementOptional()"
                            >
                              <Button
                                buttonType="reset"
                                type="primary-small"
                                >{{ $t('contract.skip') }}</Button
                              >
                            </div>
                          </form>
                        </div>

                        <div
                          v-if="contentTop == 0 && contentType == 'date'"
                          class="content-box__controll-wrap"
                          :style="{ bottom: +'10px' }"
                        >
                          <v-toolbar
                            dense
                            flat
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              @click="closeOpenedContentType"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <form
                            @submit.prevent="saveContentType"
                            @reset.prevent="saveContentType"
                          >
                            <div>
                              <span class="controll-title">{{ activeElementTitle }}</span>
                            </div>

                            <div v-if="activeElementDescription">
                              <span class="controll-desc">{{ activeElementDescription }}</span>
                            </div>

                            <div>
                              <SDatePicker
                                v-model="choiceDate"
                                :label="$t('contract.date')"
                                :placeholder="activeElementPlaceholder"
                              />
                            </div>

                            <div class="content-box__controll__buttons">
                              <Button
                                buttonType="submit"
                                type="primary-small"
                                >{{ $t('general.save') }}</Button
                              >
                            </div>

                            <div
                              class="content-box__controll__buttons"
                              v-if="isActiveElementOptional()"
                            >
                              <Button
                                buttonType="reset"
                                type="primary-small"
                                >{{ $t('contract.skip') }}</Button
                              >
                            </div>
                          </form>
                        </div>

                        <div
                          v-if="contentTop && contentType === 'number'"
                          class="content-box__controll-wrap"
                          :style="{ top: contentTop + 'px' }"
                        >
                          <v-toolbar
                            dense
                            flat
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              @click="closeOpenedContentType"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <form
                            @submit.prevent="saveContentType"
                            @reset.prevent="saveContentType"
                          >
                            <div>
                              <span class="controll-title">{{ activeElementTitle }}</span>
                            </div>

                            <div v-if="activeElementDescription">
                              <span class="controll-desc">{{ activeElementDescription }}</span>
                            </div>

                            <div>
                              <Input
                                type="number"
                                :placeholder="activeElementPlaceholder"
                                v-model="activeContent"
                              />
                            </div>

                            <div class="content-box__controll__buttons">
                              <Button
                                buttonType="submit"
                                type="primary-small"
                                >{{ $t('general.save') }}</Button
                              >
                            </div>

                            <div
                              class="content-box__controll__buttons"
                              v-if="isActiveElementOptional()"
                            >
                              <Button
                                buttonType="reset"
                                type="primary-small"
                                >{{ $t('contract.skip') }}</Button
                              >
                            </div>
                          </form>
                        </div>

                        <div
                          v-if="contentTop == 0 && contentType === 'number'"
                          class="content-box__controll-wrap"
                          :style="{ bottom: '10px' }"
                        >
                          <v-toolbar
                            dense
                            flat
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              @click="closeOpenedContentType"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <form
                            @submit.prevent="saveContentType"
                            @reset.prevent="saveContentType"
                          >
                            <div>
                              <span class="controll-title">{{ activeElementTitle }}</span>
                            </div>

                            <div v-if="activeElementDescription">
                              <span class="controll-desc">{{ activeElementDescription }}</span>
                            </div>

                            <div>
                              <Input
                                type="number"
                                :placeholder="activeElementPlaceholder"
                                v-model="activeContent"
                              />
                            </div>

                            <div class="content-box__controll__buttons">
                              <Button
                                buttonType="submit"
                                type="primary-small"
                                >{{ $t('general.save') }}</Button
                              >
                            </div>

                            <div
                              class="content-box__controll__buttons"
                              v-if="isActiveElementOptional()"
                            >
                              <Button
                                buttonType="reset"
                                type="primary-small"
                                >{{ $t('contract.skip') }}</Button
                              >
                            </div>
                          </form>
                        </div>

                        <div
                          v-if="contentTop && contentType == 'part'"
                          class="content-box__controll-wrap"
                          :style="{ top: contentTop + 'px' }"
                        >
                          <v-toolbar
                            dense
                            flat
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              @click="closeOpenedContentType"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <form @submit.prevent="savePartContentType()">
                            <div>
                              <span class="controll-title">{{ $t('contract.choose_if_article_should_be_shown') }}</span>
                            </div>

                            <div v-if="activeElementDescription">
                              <span class="controll-desc">{{ activeElementDescription }}</span>
                            </div>

                            <div>
                              <Radio
                                :value="contentOptionsChoice"
                                labelValue="show"
                                :label="$t('general.show')"
                                @change="changePartRadioValue"
                              />
                            </div>

                            <div>
                              <Radio
                                :value="contentOptionsChoice"
                                labelValue="hide"
                                :label="$t('contract.hide')"
                                @change="changePartRadioValue"
                              />
                            </div>

                            <div class="content-box__controll__buttons">
                              <Button
                                buttonType="submit"
                                type="primary-small"
                                >{{ $t('general.save') }}</Button
                              >
                            </div>

                            <div
                              class="content-box__controll__buttons"
                              v-if="isActiveElementOptional()"
                            >
                              <Button
                                buttonType="reset"
                                type="primary-small"
                                >{{ $t('contract.skip') }}</Button
                              >
                            </div>
                          </form>
                        </div>

                        <div
                          v-if="contentTop == 0 && contentType === 'part'"
                          class="content-box__controll-wrap"
                          :style="{ bottom: +'10px' }"
                        >
                          <v-toolbar
                            dense
                            flat
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              @click="closeOpenedContentType"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <form @submit.prevent="savePartContentType()">
                            <div>
                              <span class="controll-title">{{ $t('contract.choose_if_article_should_be_shown') }}</span>
                            </div>

                            <div v-if="activeElementDescription">
                              <span class="controll-desc">{{ activeElementDescription }}</span>
                            </div>

                            <div>
                              <Radio
                                :value="contentOptionsChoice"
                                labelValue="show"
                                :label="$t('general.show')"
                                @change="changePartRadioValue"
                              />
                            </div>

                            <div>
                              <Radio
                                :value="contentOptionsChoice"
                                labelValue="hide"
                                :label="$t('contract.hide')"
                                @change="changePartRadioValue"
                              />
                            </div>

                            <div class="content-box__controll__buttons">
                              <Button
                                buttonType="submit"
                                type="primary-small"
                                >{{ $t('general.save') }}</Button
                              >
                            </div>

                            <div
                              class="content-box__controll__buttons"
                              v-if="isActiveElementOptional()"
                            >
                              <Button
                                buttonType="reset"
                                type="primary-small"
                                >{{ $t('contract.skip') }}</Button
                              >
                            </div>
                          </form>
                        </div>

                        <div
                          v-if="contentTop && contentType == 'trash'"
                          class="content-box__controll-wrap"
                          :style="{ top: contentTop + 'px' }"
                        >
                          <v-toolbar
                            dense
                            flat
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              @click="cancelDeletePart"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <div>
                            <span class="controll-title">{{ $t('contract.remove_block') }}</span>
                          </div>

                          <div>
                            <span class="controll-desc">{{ $t('contract.really_want_remove_block') }}</span>
                          </div>

                          <div class="content-box__controll__buttons">
                            <Button
                              @click="deletePart"
                              buttonType="submit"
                              type="primary-small"
                              >{{ $t('general.delete') }}</Button
                            >
                          </div>

                          <div class="content-box__controll__buttons">
                            <Button
                              @click="cancelDeletePart"
                              buttonType="submit"
                              type="primary-small"
                              >{{ $t('general.cancel') }}</Button
                            >
                          </div>
                        </div>

                        <div
                          v-if="contentTop == 0 && contentType == 'trash'"
                          class="content-box__controll-wrap"
                          :style="{ bottom: +'10px' }"
                        >
                          <v-toolbar
                            dense
                            flat
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              @click="cancelDeletePart"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <div>
                            <span class="controll-title">{{ $t('contract.remove_block') }}</span>
                          </div>

                          <div>
                            <span class="controll-desc">{{ $t('contract.really_want_remove_block') }}</span>
                          </div>

                          <div class="content-box__controll__buttons">
                            <Button
                              @click="deletePart"
                              buttonType="submit"
                              type="primary-small"
                              >{{ $t('general.delete') }}</Button
                            >
                          </div>

                          <div class="content-box__controll__buttons">
                            <Button
                              @click="cancelDeletePart"
                              buttonType="submit"
                              type="primary-small"
                              >{{ $t('general.cancel') }}</Button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ContentLegend
                    v-if="contract.type === 'form'"
                    :isProposer="isProposer"
                  />
                </CreateContractDocumentTab>
              </drag>
            </drop-list>
            <CreateContractDocumentAddTab @add-attachment="attachmentPanelOpen = true" />
          </v-col>

          <v-col
            v-if="!isMobileDevice"
            class="col-md-8 contract-detail__content-view"
          >
            <div
              class="content-box my-0"
              :style="{
                marginTop: contract.attachments && contract.attachments.length > 0 ? '0' : null,
              }"
            >
              <div
                v-if="contract.type === 'form'"
                style="border-bottom: 1px solid #d8d8d8"
              >
                <ProgressLine
                  :progress="done"
                  :total="total"
                />

                <v-row
                  v-if="contentType == '' && done != total && loaded"
                  class="pl-3"
                  align="center"
                  justify="start"
                  no-gutters
                >
                  <v-col class="px-2 py-3">
                    <v-checkbox
                      v-model="autoFocusNextInput"
                      class="mt-0"
                      hide-details
                    >
                      <template v-slot:label>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">
                              {{ $t('contract.automatic_completion') }}
                            </span>
                          </template>

                          {{ $t('contract.automatic_completion_description') }}
                        </v-tooltip>
                      </template>
                    </v-checkbox>
                  </v-col>

                  <v-col
                    v-if="isKatastrEnabled"
                    cols="auto"
                    class="px-2 py-3"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        color="primary"
                        :class="{ 'text-decoration-underline': hover }"
                        text
                        @click="prefillData"
                        >{{ $t('contract.prefill.from_katastr') }}</v-btn
                      >
                    </v-hover>
                  </v-col>

                  <v-col
                    v-if="isPrefillFromContactsEnabled"
                    cols="auto"
                    class="px-2 py-3"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        color="primary"
                        :class="{ 'text-decoration-underline': hover }"
                        text
                        @click="prefillData"
                        >{{ $t('contract.prefill.from_contacts') }}</v-btn
                      >
                    </v-hover>
                  </v-col>
                </v-row>
              </div>

              <div
                class="content-box__data"
                :class="{
                  'content-box__data--full': contract.type === 'doc' || contract.type === 'wysiwyg',
                }"
              >
                <ContentPanel
                  v-if="
                    contract.type !== 'form' && (!areSigningContractRolesConsistent || isEditSignaturesForced) && loaded
                  "
                >
                  <ContentSigns
                    :signatures="contractSignatures"
                    :activeSignPlacement="activeSignPlacement"
                    :isEditSignaturesForced="isEditSignaturesForced && areSigningContractRolesConsistent"
                    @autoPlaceSign="autoPlaceSignature($event)"
                    @continueToSignButtons="isEditSignaturesForced = false"
                    @openSignaturePositionsModal="openSignaturePositionsModal"
                    @placeSign="placeSign($event)"
                    @setReadyToSend="setReadyToSend"
                  />
                </ContentPanel>

                <ContentPanel v-else-if="contract.type !== 'form' && loaded">
                  <ContentFinishedFull
                    :areContractRolesConsistent="areContractRolesConsistent"
                    :contractSignatures="contractSignatures"
                    contentClass="content-finished-full"
                    :confirmBeforeSend="confirmBeforeSend"
                    @goBackToEdit="isEditSignaturesForced = true"
                    @addAttachment="addAttachment()"
                  />
                </ContentPanel>

                <div
                  v-else-if="
                    contentType === '' &&
                    doneWithoutOptional === totalWithoutOptional &&
                    loaded &&
                    contract.type === 'form'
                  "
                  class="content-box--form"
                >
                  <div class="content-box__header">
                    <div v-if="contract.template && showFinish && arePartiesFilled()">
                      <ContentFinishedFull
                        contentClass="content-finished"
                        :areContractRolesConsistent="areContractRolesConsistent"
                        @addAttachment="addAttachment()"
                      />
                    </div>

                    <div v-else>
                      <ContentFinishedFull
                        contentClass="content-finished"
                        :areContractRolesConsistent="areContractRolesConsistent"
                      />
                    </div>
                  </div>
                </div>

                <div class="content-box__data__ico">
                  <IcoEnvelopeWrap />
                </div>

                <template v-if="loaded">
                  <div
                    v-if="contract && contract.type == 'form'"
                    id="contract-template-content"
                    class="contract-creation"
                    style="font-size: 16px"
                    v-html="contract.template.raw_content"
                  ></div>

                  <Drop
                    v-else
                    accepts-type="signatures"
                    @drop="dragend"
                  >
                    <template v-slot:drag-image="{ data }">
                      <div
                        :class="`
                          sign-placement__preview
                          ${isDefaultSignHeaderIgnored ? 'sign-placement__preview__no_header' : ''}
                        `"
                      >
                        <div class="sign-placement__header sign-placement__header-move-top">
                          <span class="sign-placement__date">
                            {{ $t('signature.header.placeholder') }}
                          </span>
                        </div>

                        <div class="sign-placement__inner">
                          <span class="sign-placement__title">
                            <div class="signature-img signature-img--empty"></div>
                          </span>

                          <span class="sign-placement__name">{{ data.firstname }} {{ data.lastname }}</span>
                        </div>

                        <div class="sign-placement__dots"></div>
                      </div>
                    </template>

                    <div
                      class="showImages-wrapper"
                      style="position: relative"
                    >
                      <div
                        style="position: relative"
                        class="showImages"
                      >
                        <div
                          v-for="(page, pageIndex) in showReducedVersion ? [currentPage] : pages"
                          :key="pageIndex"
                          class="page"
                          :class="{ 'page--last': showReducedVersion ? true : pageIndex === numberOfPages - 1 }"
                          :data-page-index="showReducedVersion ? activePageIndex : pageIndex"
                          @click="focusedSignatures = []"
                        >
                          <pdf
                            :id="showReducedVersion ? activePageIndex : pageIndex"
                            :src="documentLink"
                            :page="page.pageNumber"
                            style="z-index: -1"
                          >
                            <template v-slot:loading>
                              <DocumentPageLoader />
                            </template>
                          </pdf>

                          <SignaturePlaceholder
                            v-for="(signature, index) in signaturesPerPage[
                              showReducedVersion ? activePageIndex : pageIndex
                            ]"
                            v-model="signaturesPerPage[showReducedVersion ? activePageIndex : pageIndex][index]"
                            :key="signature.uniqueSignId"
                            :is-default-disabled-header="isDefaultSignHeaderIgnored"
                            :is-default-disabled-footer="!isDefaultSignFooterIgnored"
                            :updated-placeholder-width="updatedPlaceholderWidth || workspacePlaceholderWidth"
                            :workspacePlaceholderWidth="workspacePlaceholderWidth"
                            :isInconsistentPlaceholder="isInconsistentPlaceholder(signature)"
                            @stopSignatureDrag="stopSignatureDrag($event)"
                            @placeAnotherSign="placeAnotherSign(signature)"
                            @blurSignature="blurSignature(signature.uniqueSignId)"
                            @deleteSignature="deleteSignature(signature)"
                            @setDraggingSignature="startSignatureDrag(signature, $event)"
                            @setNewWidth="setPlaceholderWidth(signature, $event)"
                            @on-resize-placeholder="updatedPlaceholderWidth = $event"
                            @on-changes-done="savePositionsInBackend"
                            @swap-identities="onSwapIdentities"
                          />
                        </div>
                      </div>
                    </div>
                  </Drop>
                </template>

                <template v-else>
                  <div>
                    <Loader
                      class="register-loader"
                      style="text-align: center; padding-left: 0"
                    />
                  </div>
                </template>
              </div>

              <div
                v-if="contract.type === 'form' && loaded"
                class="content-box__controll"
                :style="[
                  contentType !== '' || (contentType === '' && done === total && !closedSuccessBox && !showFinish)
                    ? { display: 'block' }
                    : { display: 'none' },
                ]"
              >
                <div class="content-box__controll--boxwrap">
                  <div
                    v-if="contentType === 'options'"
                    class="content-box__controll-wrap"
                    :style="{ top: contentTop + 'px' }"
                  >
                    <v-toolbar
                      dense
                      flat
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="closeOpenedContentType"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <form
                      @submit.prevent="saveContentType"
                      @reset.prevent="saveContentType"
                    >
                      <div>
                        <span class="controll-title">{{ activeElementTitle }}</span>
                      </div>

                      <div v-if="activeElementDescription">
                        <span class="controll-desc">{{ activeElementDescription }}</span>
                      </div>

                      <div v-if="contentOptions && contentOptions.length">
                        <Radio
                          v-for="(option, index) in contentOptions"
                          :key="index"
                          :value="contentOptionsChoice"
                          :labelValue="option.value"
                          :label="option.label"
                          @change="changeRadioValue"
                        />
                      </div>

                      <div class="content-box__controll__buttons">
                        <Button
                          buttonType="submit"
                          :disabled="activeContent == ''"
                          type="primary-small"
                          >{{ $t('general.save') }}</Button
                        >
                      </div>

                      <div
                        class="content-box__controll__buttons"
                        v-if="isActiveElementOptional()"
                      >
                        <Button
                          buttonType="reset"
                          type="primary-small"
                          >{{ $t('contract.skip') }}</Button
                        >
                      </div>
                    </form>
                  </div>

                  <div
                    v-if="contentTop && contentType == 'textarea'"
                    class="content-box__controll-wrap"
                    :style="{ top: contentTop + 'px' }"
                  >
                    <v-toolbar
                      dense
                      flat
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="closeOpenedContentType"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <form
                      @submit.prevent="saveContentType"
                      @reset.prevent="saveContentType"
                    >
                      <div>
                        <span class="controll-title">{{ activeElementTitle }}</span>
                      </div>

                      <div v-if="activeElementDescription">
                        <span class="controll-desc">{{ activeElementDescription }}</span>
                      </div>

                      <div class="wysiwyg-editor">
                        <editor-menu-bar
                          class="wysiwyg-editor-menu"
                          :editor="activeContent"
                          v-slot="{ commands, isActive }"
                        >
                          <div>
                            <button
                              :class="{ 'is-active': isActive.bold() }"
                              @click.prevent="commands.bold"
                              :style="{ fontWeight: 'bold' }"
                            >
                              B
                            </button>
                            <button
                              :class="{ 'is-active': isActive.italic() }"
                              @click.prevent="commands.italic"
                              :style="{ fontStyle: 'italic' }"
                            >
                              I
                            </button>
                            <button
                              :class="{ 'is-active': isActive.underline() }"
                              @click.prevent="commands.underline"
                              :style="{ textDecoration: 'underline' }"
                            >
                              U
                            </button>
                          </div>
                        </editor-menu-bar>

                        <editor-content
                          class="wysiwyg-editor-content"
                          :placeholder="activeElementPlaceholder"
                          :editor="activeContent"
                        />
                      </div>

                      <div class="content-box__controll__buttons">
                        <Button
                          buttonType="submit"
                          type="primary-small"
                          >{{ $t('general.save') }}</Button
                        >
                      </div>

                      <div
                        class="content-box__controll__buttons"
                        v-if="isActiveElementOptional()"
                      >
                        <Button
                          buttonType="reset"
                          type="primary-small"
                          >{{ $t('contract.skip') }}</Button
                        >
                      </div>

                      <div>
                        <small class="controll-desc">{{ $t('contract.press_alt_enter') }}</small>
                      </div>
                    </form>
                  </div>

                  <div
                    v-if="contentTop == 0 && contentType == 'textarea'"
                    class="content-box__controll-wrap"
                    :style="{ bottom: +'10px' }"
                  >
                    <v-toolbar
                      dense
                      flat
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="closeOpenedContentType"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <form
                      @submit.prevent="saveContentType"
                      @reset.prevent="saveContentType"
                    >
                      <div>
                        <span class="controll-title">{{ activeElementTitle }}</span>
                      </div>

                      <div v-if="activeElementDescription">
                        <span class="controll-desc">{{ activeElementDescription }}</span>
                      </div>

                      <div class="wysiwyg-editor">
                        <editor-menu-bar
                          class="wysiwyg-editor-menu"
                          :editor="activeContent"
                          v-slot="{ commands, isActive }"
                        >
                          <div>
                            <button
                              :class="{ 'is-active': isActive.bold() }"
                              @click.prevent="commands.bold"
                              :style="{ fontWeight: 'bold' }"
                            >
                              B
                            </button>
                            <button
                              :class="{ 'is-active': isActive.italic() }"
                              @click.prevent="commands.italic"
                              :style="{ fontStyle: 'italic' }"
                            >
                              I
                            </button>
                            <button
                              :class="{ 'is-active': isActive.underline() }"
                              @click.prevent="commands.underline"
                              :style="{ textDecoration: 'underline' }"
                            >
                              U
                            </button>
                          </div>
                        </editor-menu-bar>

                        <editor-content
                          class="wysiwyg-editor-content"
                          :placeholder="activeElementPlaceholder"
                          :editor="activeContent"
                        />
                      </div>

                      <div class="content-box__controll__buttons">
                        <Button
                          buttonType="submit"
                          type="primary-small"
                          >{{ $t('general.save') }}</Button
                        >
                      </div>

                      <div
                        class="content-box__controll__buttons"
                        v-if="isActiveElementOptional()"
                      >
                        <Button type="primary-small">{{ $t('contract.skip') }}</Button>
                      </div>
                    </form>
                  </div>

                  <div
                    v-if="contentTop && contentType == 'date'"
                    class="content-box__controll-wrap"
                    :style="{ top: contentTop + 'px' }"
                  >
                    <v-toolbar
                      dense
                      flat
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="closeOpenedContentType"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <form
                      @submit.prevent="saveContentType"
                      @reset.prevent="saveContentType"
                    >
                      <div>
                        <span class="controll-title">{{ activeElementTitle }}</span>
                      </div>

                      <div v-if="activeElementDescription">
                        <span class="controll-desc">{{ activeElementDescription }}</span>
                      </div>

                      <div>
                        <SDatePicker
                          v-model="choiceDate"
                          :label="$t('contract.date')"
                          :placeholder="activeElementPlaceholder"
                        />
                      </div>

                      <div class="content-box__controll__buttons">
                        <Button
                          buttonType="submit"
                          type="primary-small"
                          >{{ $t('general.save') }}</Button
                        >
                      </div>

                      <div
                        class="content-box__controll__buttons"
                        v-if="isActiveElementOptional()"
                      >
                        <Button
                          buttonType="reset"
                          type="primary-small"
                          >{{ $t('contract.skip') }}</Button
                        >
                      </div>
                    </form>
                  </div>

                  <div
                    v-if="contentTop == 0 && contentType == 'date'"
                    class="content-box__controll-wrap"
                    :style="{ bottom: +'10px' }"
                  >
                    <v-toolbar
                      dense
                      flat
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="closeOpenedContentType"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <form
                      @submit.prevent="saveContentType"
                      @reset.prevent="saveContentType"
                    >
                      <div>
                        <span class="controll-title">{{ activeElementTitle }}</span>
                      </div>

                      <div v-if="activeElementDescription">
                        <span class="controll-desc">{{ activeElementDescription }}</span>
                      </div>

                      <div>
                        <SDatePicker
                          v-model="choiceDate"
                          :label="$t('contract.date')"
                          :placeholder="activeElementPlaceholder"
                        />
                      </div>

                      <div class="content-box__controll__buttons">
                        <Button
                          buttonType="submit"
                          type="primary-small"
                          >{{ $t('general.save') }}</Button
                        >
                      </div>

                      <div
                        class="content-box__controll__buttons"
                        v-if="isActiveElementOptional()"
                      >
                        <Button
                          buttonType="reset"
                          type="primary-small"
                          >{{ $t('contract.skip') }}</Button
                        >
                      </div>
                    </form>
                  </div>

                  <div
                    v-if="contentTop && contentType == 'number'"
                    class="content-box__controll-wrap"
                    :style="{ top: contentTop + 'px' }"
                  >
                    <v-toolbar
                      dense
                      flat
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="closeOpenedContentType"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <form
                      @submit.prevent="saveContentType"
                      @reset.prevent="saveContentType"
                    >
                      <div>
                        <span class="controll-title">{{ activeElementTitle }}</span>
                      </div>

                      <div v-if="activeElementDescription">
                        <span class="controll-desc">{{ activeElementDescription }}</span>
                      </div>

                      <div>
                        <Input
                          type="number"
                          :placeholder="activeElementPlaceholder"
                          v-model="activeContent"
                        />
                      </div>

                      <div class="content-box__controll__buttons">
                        <Button
                          buttonType="submit"
                          type="primary-small"
                          >{{ $t('general.save') }}</Button
                        >
                      </div>

                      <div
                        class="content-box__controll__buttons"
                        v-if="isActiveElementOptional()"
                      >
                        <Button
                          buttonType="reset"
                          type="primary-small"
                          >{{ $t('contract.skip') }}</Button
                        >
                      </div>
                    </form>
                  </div>

                  <div
                    v-if="contentTop == 0 && contentType == 'number'"
                    class="content-box__controll-wrap"
                    :style="{ bottom: '10px' }"
                  >
                    <v-toolbar
                      dense
                      flat
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="closeOpenedContentType"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <form
                      @submit.prevent="saveContentType"
                      @reset.prevent="saveContentType"
                    >
                      <div>
                        <span class="controll-title">{{ activeElementTitle }}</span>
                      </div>

                      <div v-if="activeElementDescription">
                        <span class="controll-desc">{{ activeElementDescription }}</span>
                      </div>

                      <div>
                        <Input
                          type="number"
                          :placeholder="activeElementPlaceholder"
                          v-model="activeContent"
                        />
                      </div>

                      <div class="content-box__controll__buttons">
                        <Button
                          buttonType="submit"
                          type="primary-small"
                          >{{ $t('general.save') }}</Button
                        >
                      </div>

                      <div
                        class="content-box__controll__buttons"
                        v-if="isActiveElementOptional()"
                      >
                        <Button
                          buttonType="reset"
                          type="primary-small"
                          >{{ $t('contract.skip') }}</Button
                        >
                      </div>
                    </form>
                  </div>

                  <div
                    v-if="contentTop && contentType == 'part'"
                    class="content-box__controll-wrap"
                    :style="{ top: contentTop + 'px' }"
                  >
                    <v-toolbar
                      dense
                      flat
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="closeOpenedContentType"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <form @submit.prevent="savePartContentType()">
                      <div>
                        <span class="controll-title">{{ $t('contract.choose_if_article_should_be_shown') }}</span>
                      </div>

                      <div v-if="activeElementDescription">
                        <span class="controll-desc">{{ activeElementDescription }}</span>
                      </div>

                      <div>
                        <Radio
                          :value="contentOptionsChoice"
                          labelValue="show"
                          :label="$t('general.show')"
                          @change="changePartRadioValue"
                        />
                      </div>

                      <div>
                        <Radio
                          :value="contentOptionsChoice"
                          labelValue="hide"
                          :label="$t('contract.hide')"
                          @change="changePartRadioValue"
                        />
                      </div>

                      <div class="content-box__controll__buttons">
                        <Button
                          buttonType="submit"
                          type="primary-small"
                          >{{ $t('general.save') }}</Button
                        >
                      </div>

                      <div
                        class="content-box__controll__buttons"
                        v-if="isActiveElementOptional()"
                      >
                        <Button
                          buttonType="reset"
                          type="primary-small"
                          >{{ $t('contract.skip') }}</Button
                        >
                      </div>
                    </form>
                  </div>

                  <div
                    v-if="contentTop == 0 && contentType == 'part'"
                    class="content-box__controll-wrap"
                    :style="{ bottom: +'10px' }"
                  >
                    <v-toolbar
                      dense
                      flat
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="closeOpenedContentType"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <form @submit.prevent="savePartContentType()">
                      <div>
                        <span class="controll-title">{{ $t('contract.choose_if_article_should_be_shown') }}</span>
                      </div>

                      <div v-if="activeElementDescription">
                        <span class="controll-desc">{{ activeElementDescription }}</span>
                      </div>

                      <div>
                        <Radio
                          :value="contentOptionsChoice"
                          labelValue="show"
                          :label="$t('general.show')"
                          @change="changePartRadioValue"
                        />
                      </div>

                      <div>
                        <Radio
                          :value="contentOptionsChoice"
                          labelValue="hide"
                          :label="$t('contract.hide')"
                          @change="changePartRadioValue"
                        />
                      </div>

                      <div class="content-box__controll__buttons">
                        <Button
                          buttonType="submit"
                          type="primary-small"
                          >{{ $t('general.save') }}</Button
                        >
                      </div>

                      <div
                        class="content-box__controll__buttons"
                        v-if="isActiveElementOptional()"
                      >
                        <Button
                          buttonType="reset"
                          type="primary-small"
                          >{{ $t('contract.skip') }}</Button
                        >
                      </div>
                    </form>
                  </div>

                  <div
                    v-if="contentTop && contentType === 'trash'"
                    class="content-box__controll-wrap"
                    :style="{ top: contentTop + 'px' }"
                  >
                    <v-toolbar
                      dense
                      flat
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="cancelDeletePart"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <div>
                      <span class="controll-title">{{ $t('contract.remove_block') }}</span>
                    </div>

                    <div>
                      <span class="controll-desc">{{ $t('contract.really_want_remove_block') }}</span>
                    </div>

                    <div class="content-box__controll__buttons">
                      <Button
                        @click="deletePart"
                        buttonType="submit"
                        type="primary-small"
                        >{{ $t('general.delete') }}</Button
                      >
                    </div>

                    <div class="content-box__controll__buttons">
                      <Button
                        @click="cancelDeletePart"
                        buttonType="submit"
                        type="primary-small"
                        >{{ $t('general.cancel') }}</Button
                      >
                    </div>
                  </div>

                  <div
                    v-if="contentTop == 0 && contentType == 'trash'"
                    class="content-box__controll-wrap"
                    :style="{ bottom: +'10px' }"
                  >
                    <v-toolbar
                      dense
                      flat
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="cancelDeletePart"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <div>
                      <span class="controll-title">{{ $t('contract.remove_block') }}</span>
                    </div>

                    <div>
                      <span class="controll-desc">{{ $t('contract.really_want_remove_block') }}</span>
                    </div>

                    <div class="content-box__controll__buttons">
                      <Button
                        @click="deletePart"
                        buttonType="submit"
                        type="primary-small"
                        >{{ $t('general.delete') }}</Button
                      >
                    </div>

                    <div class="content-box__controll__buttons">
                      <Button
                        @click="cancelDeletePart"
                        buttonType="submit"
                        type="primary-small"
                        >{{ $t('general.cancel') }}</Button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <v-row
              class="pt-0"
              align="center"
              justify="center"
            >
              <v-col cols="12">
                <ContentLegend
                  v-if="contract.type === 'form'"
                  :isProposer="isProposer"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col
            v-if="!isMobileDevice"
            class="col-md-1"
            style="background-color: #f2f6fc; position: relative"
          >
            <DocumentPagesPagination v-if="contract.type === 'doc' && showReducedVersion" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <AttachmentPanel
      :attachmentPanelOpen="attachmentPanelOpen"
      :disable-forms="isFormsDisabled"
      @close="attachmentPanelOpen = false"
    />

    <SignaturePositionsModal
      v-if="isSignaturePositionsModalOpened"
      :contract="contract"
      @select="loadPositionsFromHistory"
      @close="closeSignaturePositionsModal()"
    />
    <SConfirmDialog
      v-model="showConfirmChangeDocumentDialog"
      :message="`${$t('contract.wysiwyg.confirm')}`"
      @confirmation-callback="confirmChangeDocument"
    />
  </Data>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { useContractSignaturesPlaceholdersStore } from '@/features/contract/stores/contractSignaturesPlaceholdersStore';
import { ContractService } from '@/services/ContractService.js';
import { ContractHelper } from '@/helpers/ContractHelper';
import { WorkflowHelper } from '@/helpers/WorkflowHelper.js';
import { PlacedSignatures } from '@/helpers/PlacedSignatures.js';
import { getElementBounds, getPage } from '@/services/helpers/signatureHelper';
import { getFormattedDate } from '@/common/reusable/dateFunctions';
import { Drop, DropList, Drag } from 'vue-easy-dnd';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import { HardBreak, Bold, Italic, Underline, Placeholder } from 'tiptap-extensions';
import { internalApiRequest } from '@/shared/services/apiService';
import _ from 'lodash';
import FeatureFlags from '@/common/reusable/featureFlagsChecker';
import ProgressLine from '@/components/ProgressLine.vue';
import ContentLegend from '@/components/ContentLegend.vue';
import ContentFinishedFull from '@/views/create/components/ContentFinishedFull';
import ContentSigns from '@/views/create/components/ContentSigns';
import SignaturePlaceholder from '@/features/signature/components/SignaturePlaceholder';
import Note from '@/components/Note.vue';
import IcoEnvelopeWrap from '@/components/svg/IcoEnvelopeWrap.vue';
import Loader from '@/components/Loader.vue';
import AttachmentPanel from '@/components/AttachmentPanel.vue';
import moment from 'moment';
import IcoInfo from '@/components/svg/IcoInfo';
import SignaturePositionsModal from '@/views/create/components/SignaturePositionsModal';
import ContentPanel from '@/views/create/components/ContentPanel';
import CreateContractDocumentTab from '@/features/contract/components/CreateContractDocumentTab';
import CreateContractDocumentAddTab from '@/features/contract/components/CreateContractDocumentAddTab';
import SDatePicker from '@/common/components/SDatePicker';
import DocumentPagesPagination from '@/app/features/document/components/DocumentPagesPagination.vue';
import SConfirmDialog from '@/common/components/SConfirmDialog';
import pdf from '@digitalfactorycz/pdf-viewer';
import DocumentPageLoader from '@/common/skeletons/DocumentPageLoader';
import { useTemplateFieldsStore } from '@/features/contract/stores/templateFieldsStore';
import { useSignatureFooterSettingsStore } from '@/features/contracts/stores/signatureFooterSettings';
import { useDocumentStore } from '@/app/features/document/store/document';
import { storeToRefs } from 'pinia';

const $ = window.jQuery;
const jQuery = window.jQuery;

$(document).ready(function ($) {
  $(window).scroll(function () {
    var scroll = $(window).scrollTop(); // how many pixels you've scrolled

    if ($('.content-box__data').length) {
      var contentBoxTop = $('.content-box__data').offset().top; // pixels to the top of div1
      var headerHeight = $('.header').height(); // height of div1 in pixels

      if (scroll > contentBoxTop - headerHeight) {
        $('.progress-line').addClass('fixed');
        $('.content-box__header').addClass('fixed');
        $('.content-box__header').css('margin-top');
      } else {
        $('.progress-line').removeClass('fixed');
        $('.content-box__header').removeClass('fixed');
      }
    }
  });
});

let signaturesHelper;

export default defineComponent({
  name: 'CreateContent',
  components: {
    DocumentPageLoader,
    DocumentPagesPagination,
    SDatePicker,
    SConfirmDialog,
    CreateContractDocumentAddTab,
    CreateContractDocumentTab,
    IcoInfo,
    ProgressLine,
    ContentLegend,
    ContentFinishedFull,
    IcoEnvelopeWrap,
    Note,
    Loader,
    AttachmentPanel,
    EditorContent,
    EditorMenuBar,
    SignaturePositionsModal,
    ContentSigns,
    ContentPanel,
    Drop,
    SignaturePlaceholder,
    pdf,
    DropList,
    Drag,
  },
  setup() {
    const templateFieldsStore = useTemplateFieldsStore();
    const signatureFooterSettingsStore = useSignatureFooterSettingsStore();

    const contractSignaturesPlaceholdersStore = useContractSignaturesPlaceholdersStore();
    const {
      fetchAllSignatures: fetchContractsSignaturesPlaceholders,
      saveSignaturePosition: saveSignaturesPlaceholdersPositions,
    } = contractSignaturesPlaceholdersStore;

    const documentStore = useDocumentStore();
    const { activePageIndex, currentPageNumber } = storeToRefs(documentStore);
    const { setCurrentPageNumber, reset: resetDocumentStore } = documentStore;

    return {
      templateFieldsStore,
      signatureFooterSettingsStore,
      fetchContractsSignaturesPlaceholders,
      saveSignaturesPlaceholdersPositions,
      activePageIndex,
      currentPageNumber,
      setCurrentPageNumber,
      resetDocumentStore,
    };
  },
  data() {
    signaturesHelper = PlacedSignatures.build((newSignatures) => {
      this.signatures = [...newSignatures.map((signature) => signature)];
      this.setCreateSignatures(this.signatures);
      this.contractSignatures = this.contractSignIdentities
        ? _.map(this.contractSignIdentities, (signIdentity) => {
            const isPlaced = signaturesHelper.findSignatures(signIdentity).length > 0;
            return {
              is_proposer_sign: signIdentity.is_proposer,
              firstname: signIdentity.firstname,
              lastname: signIdentity.lastname,
              signIdentity: signIdentity,
              is_placed: isPlaced,
              is_inconsistent: ContractHelper.isInconsistentContractRole(signIdentity, isPlaced),
            };
          })
        : [];
    });
    return {
      swappingSignatures: false,
      createSignatureCopy: false,
      updatedPlaceholderWidth: null,
      workspacePlaceholderWidth: 30,
      showImages: '',
      loaded: false,
      showModal: false,
      selected: '',
      progressNumber: 2,
      progressLineNumber: 1,
      controlEnabled: false,
      modalClose: true,
      editBtnModal: false,
      content: '',
      activeElement: '',
      activeContent: '',
      options: [],
      data: [],
      contentType: '',
      contentTop: 0,
      contentId: 0,
      contentOptions: [],
      totalFields: 0,
      contentOptionsChoice: '',
      contentBind: '',
      total: 1,
      done: 0,
      totalWithoutOptional: 0,
      doneWithoutOptional: 0,
      selectedDate: new Date().toISOString().substr(0, 10),
      choiceDate: new Date().toISOString().substr(0, 10),
      showFinish: false,
      activeElementTitle: '',
      activeElementDescription: '',
      activeElementPlaceholder: '',
      activeSignPlacement: '',
      signCounterCoordinates: '',
      showConfirmChangeDocumentDialog: false,

      attachmentPanelOpen: false,
      closedSuccessBox: false,

      contractPreview: [],
      signatures: [],
      contractSignatures: [],
      isEditSignaturesForced: false,
      isSignaturePositionsModalOpened: false,

      draggingSignature: null,
      firstDragPositions: null,

      errorMessage: [],
      autoFocusNextInput: false,
      focusedSignatures: [],
      highlight: false,
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      profile: 'profile',
      myIdentity: 'myIdentity',
      workspaceById: 'workspaceById',
      document: 'pdfDocument/document',
      documentPages: 'pdfDocument/documentPages',
      documentLink: 'pdfDocument/documentLink',
      documentInfo: 'pdfDocument/documentInfo',
      isAllPagesLoaded: 'pdfDocument/isAllPagesLoaded',
      numberOfPages: 'pdfDocument/numberOfPages',
      showReducedVersion: 'pdfDocument/showReducedVersion',
    }),
    currentPage() {
      return this.pages[this.activePageIndex];
    },
    pages() {
      return new Array(this.numberOfPages).fill({}).map((item, index) => {
        const pageNumber = 1 + index;

        return {
          id: `document-page-${pageNumber}`,
          loaded: false,
          pageNumber,
        };
      });
    },
    isHighlited() {
      return this.highlight;
    },
    signaturesPerPage() {
      const signaturesPerPage = [];

      for (const page of this.pages) {
        signaturesPerPage[page.pageNumber - 1] =
          this.signatures?.filter((signature) => {
            return page.pageNumber - 1 === signature.page;
          }) || [];
      }

      return signaturesPerPage;
    },
    availableDocuments() {
      return 0 === this.contract?.attachments?.length ? [this.contract] : this.contract.attachments;
    },
    areContractRolesConsistent() {
      return this.contractSignatures.every((signature) => !signature.is_inconsistent);
    },
    areSigningContractRolesConsistent() {
      return this.contractSignatures
        .filter((signature) => ContractHelper.isSigningContractRole(signature.signIdentity))
        .every((signature) => !signature.is_inconsistent);
    },
    documentPreviews() {
      return this.contractPreview;
    },
    isContractOwner() {
      return this.documentInfo?.user_id ? this.documentInfo.user_id === this.profile.id : false;
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
    isKatastrEnabled() {
      return FeatureFlags.isKatastrEnabled(this.contract);
    },
    isPrefillFromContactsEnabled() {
      return FeatureFlags.isPrefillFromContactsEnabled(this.contract);
    },
    isDefaultSignHeaderIgnored() {
      const isHeaderHidden = FeatureFlags.showSignDateAndPlaceDisabled(
        this.workspaceById(this.$route.params.workspace_id),
      );

      return 'undefined' !== typeof isHeaderHidden ? isHeaderHidden : false;
    },
    isDefaultSignFooterIgnored() {
      return !Object.keys(this.signatureFooterSettingsStore?.settings)?.some((e) => {
        return true === this.signatureFooterSettingsStore?.settings[e];
      });
    },
    isFormsDisabled() {
      return !!this.contract?.sign_identities?.find((identity) => {
        return ['sign_certificate', 'sign_bank_id_sign', 'sign_type_a'].includes(identity?.contract_role);
      });
    },
    contentFilled() {
      return (
        'form' === this.contract.type &&
        this.contract.template &&
        '' === this.contentType &&
        this.done === this.total &&
        this.loaded &&
        this.showFinish &&
        this.arePartiesFilled()
      );
    },
    isProposer() {
      return this.contract?.current_sign_identity?.is_proposer;
    },
  },
  watch: {
    '$vuetify.breakpoint.width': {
      handler: function () {
        this.checkModal();
      },
    },
    areSigningContractRolesConsistent(newVal) {
      if (this.contract.type !== 'form') {
        if (true === newVal) {
          this.isEditSignaturesForced = false;
          this.$notification.success(this.$t('contract.positions.done'));
          return;
        }
        this.$notification.success(this.$t('contract.positions.ready'));
      }
    },
    contentFilled() {
      if (this.contentFilled) {
        this.setIsCreateContentFilled();

        return;
      }

      this.resetIsCreateContentFilled();
    },
    draggingSignature: function () {
      if (this.draggingSignature) {
        const $showImages = document.querySelector('.showImages');

        $showImages.addEventListener('touchstart', this.signDragStartTouch, false);
        $showImages.addEventListener('touchmove', this.signDrag, false);

        $showImages.addEventListener('mousedown', this.signDragStart, false);
        $showImages.addEventListener('mousemove', this.signDrag, false);
      }
    },
    activeContent: function () {
      if (
        this.contentType != 'options' &&
        !(this.activeContent instanceof Editor) &&
        this.activeContent &&
        this.activeContent.length > 1
      ) {
        jQuery(this.activeElement).html(this.activeContent);
      }
    },
    doneWithoutOptional: function () {
      if (this.doneWithoutOptional == this.totalWithoutOptional) {
        this.showFinish = true;
        this.closedSuccessBox = false;
        this.templateFieldsStore.setCompletedTemplates(this.contract?.id);
      }
    },
    totalWithoutOptional: function () {
      if (this.doneWithoutOptional == this.totalWithoutOptional) {
        this.showFinish = true;
        this.closedSuccessBox = false;
        this.templateFieldsStore.setCompletedTemplates(this.contract?.id);
      }
    },
    activeElement: function (newElem, oldElem) {
      if (oldElem) oldElem[0].classList.remove('selected');

      if (this.activeElement) {
        this.activeElement.closest('con-section').addClass('editing');

        if (!this.activeElement[0].classList.contains('selected')) {
          this.focusConInput(this.activeElement[0]);
        }
      }
    },
    showFinish: function () {
      if (
        this.contract.template &&
        '' == this.contentType &&
        this.doneWithoutOptional == this.totalWithoutOptional &&
        this.loaded &&
        this.showFinish &&
        this.arePartiesFilled() &&
        window.innerWidth > 991
      ) {
        jQuery(document).ready(function ($) {
          $('html, body').animate({ scrollTop: 0 }, 600);
        });
      }
    },
    contentType() {
      if ('' === this.contentType) {
        this.activeContent = '';
        this.activeElement = '';
      }
    },
    contract(newVal) {
      if (newVal && Object.keys(newVal).length > 0 && newVal.workspace_branding) {
        this.workspacePlaceholderWidth = newVal.workspace_branding.signatureField.relativeWidth;
      }
    },
    choiceDate(date) {
      const currentDate = new Date(date);
      let old = this.activeContent;

      this.activeContent =
        currentDate.getDate() + '. ' + (currentDate.getMonth() + 1) + '. ' + currentDate.getFullYear();
      this.selectedDate = moment(currentDate).toDate();

      if (old !== '' && old !== this.activeContent && 'date' === this.contentType) {
        this.$nextTick(() => {
          this.activeElement[0].classList.add('edited');
          this.saveAndGoToNextElement();
        });
      }
    },
    documentLink: {
      handler: function (documentLink) {
        if (typeof documentLink !== 'undefined') {
          this.loadDocument(documentLink);
        }
      },
    },
  },
  async created() {
    this.resetIsCreateContentFilled();
    this.resetDocument();

    this.contractSignIdentities = await this.fetchSignIdentities({
      contractId: this.$route.params.contract_id,
      routeName: this.$route.name,
    });
    this.currentSignIdentity = await this.fetchCurrentSignIdentity(this.$route.params.contract_id);

    this.signatureFooterSettingsStore.fetchSettings(this.$route.params.workspace_id);

    ContractService.detail(
      this.$route.params.contract_id,
      this.$route.params.workspace_id,
      this.$route.params.attachment_id || null,
    )
      .then(async (response) => {
        await this.setContract(response);

        this.fetchContractsSignaturesPlaceholders(response);

        if (WorkflowHelper.redirectIfContractIsNotEditableInWizard(response)) {
          return;
        }

        this.contract.one_device = this.contract.one_device ? 1 : 0;

        if (!this.arePartiesFilled()) {
          this.$notification.error(this.$t('contract.create.error.parties_empty'));

          return this.$router.push({
            name: 'contractsCollectionParties',
            params: {
              contractId: this.contract?.main_contract_id || this.contract?.id,
              workspaceId: this.$route.params.workspace_id,
            },
          });
        }

        const contractDocuments = this.contract?.attachments.length > 0 ? this.contract?.attachments : [this.contract];

        let contractTemplates = contractDocuments.filter((document) => 'form' === document?.type);

        if (0 < contractTemplates.length) {
          this.templateFieldsStore.setContractTemplates(contractTemplates);
          await this.fetchContractTemplatesRegistered(this.contract.id, contractTemplates);
        }

        if ('form' == this.contract.type) {
          if (response.answers) {
            this.data = JSON.parse(response.answers);
          }

          this.fetchJS();
        } else if ('doc' == this.contract.type || 'wysiwyg' == this.contract.type) {
          this.getDoc();
        }

        this.recalculateElements();
      })
      .catch(() => {
        this.$notification.error(this.$t('contract.not_loaded'));
      })
      .finally(() => {
        this.loaded = true;
      });

    const vm = this;

    jQuery(document).ready(function ($) {
      function placeSignature(that) {
        if (vm.activeSignPlacement) {
          const signPlacement = vm.activeSignPlacement;
          vm.activeSignPlacement = null;

          if (signPlacement.is_proposer_sign) {
            vm.$notification.success(vm.$t('contract.positions.proposer_ok'));
          } else {
            vm.$notification.success(vm.$t('contract.positions.counterparty_ok'));
          }

          let $page = $(that);
          let pageNumber = vm.showReducedVersion ? vm.activePageIndex : that.getAttribute('data-page-number') - 1;
          let divTop = $(document).find('.showImages').offset().top;
          let divLeft = $(document).find('.showImages').offset().left;

          const pageX =
            event.pageX === undefined ? (event.changedTouches ? event.changedTouches[0].pageX : 0) : event.pageX;
          const pageY =
            event.pageY === undefined ? (event.changedTouches ? event.changedTouches[0].pageY : 0) : event.pageY;

          let x = pageX - that.offsetLeft;
          let y = pageY - that.offsetTop;

          y = y - divTop;
          x = x - divLeft;

          const distanceFromBeginning = (pageNumber) => {
            const pages = document.querySelectorAll('.page[data-page-index]');
            let sum = 0;

            for (let i = 0; i < pageNumber; i++) {
              sum += pages[i]?.getBoundingClientRect()?.height;
            }

            return sum;
          };

          let leftPercentage = (x / $(that).width()) * 100;
          let percentageToPage = vm.showReducedVersion
            ? (y / $page.height()) * 100
            : ((y - distanceFromBeginning(pageNumber)) / $page.height()) * 100;

          const data = {
            uniqueSignId: vm.createSignatureCopy ? null : signPlacement.uniqueSignId,
            page: pageNumber,
            offset_top: percentageToPage + 2,
            offset_left: leftPercentage + 2,
            show_header: signPlacement.uniqueSignId ? signPlacement.show_header : !vm.isDefaultSignHeaderIgnored,
            show_footer: signPlacement.uniqueSignId ? signPlacement.show_footer : !vm.isDefaultSignFooterIgnored,
          };

          $(document).find('.js-signature').css({
            display: 'none',
          });

          signaturesHelper.upsert(signPlacement.signIdentity, [data]);
          vm.savePositionsInBackend(() => vm.$forceUpdate());
          vm.createSignatureCopy = false;
        } else {
          $(document).find('.js-signature').css({
            display: 'none',
          });
        }
      }

      $(document).on('mousemove', function (e) {
        $(document)
          .find('.js-signature')
          .css({
            top: e.clientY + 5 + 'px',
            left: e.clientX + 5 + 'px',
          });
      });

      $(document).on('mouseenter', '.showImages', function () {
        if (vm.activeSignPlacement) {
          const pageWidth = $(document).find('.page').width();
          const widthPercentage = vm.contract.workspace_branding.signatureField.relativeWidth / 100;
          $(document)
            .find('.js-signature')
            .css({
              display: 'none',
              width: `${pageWidth * widthPercentage}px`,
            });
        } else {
          $(document).find('.js-signature').css({
            display: 'none',
          });
        }
      });

      $(document).on('mouseleave', '.showImages', function () {
        $(document).find('.js-signature').css({
          display: 'none',
        });
      });

      $(document).on('mouseup', '.showImages .page', function () {
        placeSignature(this);
      });

      $(document).on('touchend', '.showImages .page', function () {
        placeSignature(this);
      });
    });
  },
  mounted() {
    this.resetDocumentStore();

    window.addEventListener('keydown', (event) => {
      // ALT + Enter for quick save con-textarea
      if (this.activeContent && this.activeContent instanceof Editor && 13 === event.keyCode && event.altKey) {
        this.saveContentType();
      }
    });
  },
  beforeDestroy() {
    $(document).off('click', '.trash');
    $(document).off('click', 'con-text');
    $(document).off('click', 'con-textarea');
    $(document).off('click', 'con-date');
    $(document).off('click', 'con-number');
    $(document).off('click', 'con-options');
    $(document).off('click', 'con-part');
  },
  methods: {
    ...mapActions({
      setIsCreateContentFilled: 'setIsCreateContentFilled',
      resetIsCreateContentFilled: 'resetIsCreateContentFilled',
      setContract: 'setContract',
      setCreateSignatures: 'setCreateSignatures',
      fetchDocumentWithLink: 'pdfDocument/fetchDocumentWithLink',
      loadDocument: 'pdfDocument/load',
      resetDocument: 'pdfDocument/reset',
      fetchSignIdentities: 'contractSignIdentities/fetchSignIdentities',
      fetchCurrentSignIdentity: 'contractSignIdentities/fetchCurrentSignIdentity',
    }),
    isInconsistentPlaceholder(signatureOnPage) {
      return !ContractHelper.isSigningContractRole(signatureOnPage.signIdentity);
    },
    insertDocument(event) {
      this.availableDocuments.splice(event.index, 0, event.data);
    },
    reorder(event) {
      event.apply(this.availableDocuments);
      const newDocumentOrderIds = this.availableDocuments.map((item) => item.id);
      return internalApiRequest({
        method: 'PUT',
        path: 'api/v2/contracts/order',
        data: {
          contractIds: newDocumentOrderIds,
        },
      });
    },
    remove(array, value) {
      let index = array.indexOf(value);
      array.splice(index, 1);
    },
    dragend(e) {
      const trim = document.querySelector('.content-box__data').getBoundingClientRect().top + 100 + window.scrollY;
      const scrollOfPanel = window.scrollY + e.position.y - trim;
      const elementBounds = getElementBounds();
      const pageToPlace = getPage(elementBounds, scrollOfPanel);

      this.autoPlaceSignature({
        page: pageToPlace.element,
        signPlacement: this.activeSignPlacement,
        pageX: e.position.x,
        pageY: scrollOfPanel,
        pageNumber: pageToPlace.index,
      });
    },
    autoPlaceSignature({ page, signPlacement, pageY: y, pageNumber, pageX, copy = false }) {
      if (signPlacement) {
        this.activeSignPlacement = null;

        if (signPlacement && signPlacement.is_proposer_sign) {
          this.$notification.success(this.$t('contract.positions.proposer_ok'));
        } else {
          this.$notification.success(this.$t('contract.positions.counterparty_ok'));
        }

        let $page = $(page);
        let divLeft = $(document).find('.showImages').offset().left;
        let x = $(document).find('.showImages').offset().left - page.offsetLeft;

        x = pageX - divLeft || x - divLeft;

        const distanceFromBeginning = (pageNumber) => {
          const pages = document.querySelectorAll('.page[data-page-index]');
          let sum = 0;

          for (let i = 0; i < pageNumber; i++) {
            sum += pages[i]?.getBoundingClientRect()?.height;
          }

          return sum;
        };

        let leftPercentage = (x / $(page).width()) * 100;
        let percentageToPage = pageX
          ? Math.max(((y - distanceFromBeginning(pageNumber)) / $page.height()) * 100, 0.1)
          : Math.min(Math.max(((y - distanceFromBeginning(pageNumber)) / $page.height()) * 100, 1), 85);

        $(document).find('.js-signature').css({
          display: 'none',
        });

        signaturesHelper.upsert(signPlacement.signIdentity, [
          {
            uuid: signPlacement.uuid,
            anchor: signPlacement.anchor,
            uniqueSignId: copy ? null : signPlacement.uniqueSignId,
            page: this.showReducedVersion ? this.activePageIndex : pageNumber,
            offset_top: copy ? signPlacement.top + 2 : percentageToPage + 2,
            offset_left: copy ? signPlacement.left + 2 : leftPercentage + 2,
            show_header: signPlacement?.uniqueSignId ? signPlacement.show_header : !this.isDefaultSignHeaderIgnored,
            show_footer: signPlacement?.uniqueSignId ? signPlacement.show_footer : !this.isDefaultSignFooterIgnored,
          },
        ]);

        this.savePositionsInBackend(() => this.$forceUpdate());
      }
    },
    setPlaceholderWidth(signature, relativeWidth) {
      signature.relativeWidth = relativeWidth;
      this.savePositionsInBackend();
    },
    navigateBackHandler() {
      this.$router.push({
        name: 'contractsCollectionParties',
        params: {
          contractId: this.contract.id,
          workspaceId: this.$route.params?.workspace_id,
        },
      });
    },
    placeAnotherSign(placedSignature) {
      const contractSignature = Object.assign({}, placedSignature);

      this.createSignatureCopy = true;
      this.placeSign(contractSignature, true);
    },
    placeSign(signature, dontDeleteFirstSign) {
      if (!!signature?.document?.id && !signature.data) {
        return;
      }

      const deprecatedFirstPosition = signaturesHelper.findSignatures(signature.signIdentity);

      if (!dontDeleteFirstSign && deprecatedFirstPosition.length) {
        this.deleteSignature(deprecatedFirstPosition[0]);
      }

      this.$notification.warning(this.$t('contract.positions.pick_position'));
      this.activeSignPlacement = Object.assign({}, signature);

      jQuery(document).ready(function ($) {
        $(document)
          .find('.js-signature-placeholder-name')
          .text(signature.firstname + ' ' + signature.lastname);
      });
    },
    getDoc() {
      this.fetchDocumentWithLink();

      ContractService.getDoc(this.contract, 'json').then(
        (response) => {
          this.contractPreview = response.preview;
          this.loaded = true;
          setTimeout(() => {
            $(document)
              .find('.showImages')
              .append(
                `<div class='signature-placeholder js-signature'><div class='signature-placeholder__inner'><div><div class='signature-placeholder__signature'>${this.$t(
                  'general.signature',
                )}</div><div class='signature-placeholder__name js-signature-placeholder-name'></div></div></div></div>`,
              );

            this.contractSignIdentities.forEach((signIdentity) =>
              signaturesHelper.upsert(signIdentity, signIdentity.positions),
            );

            if (signaturesHelper.isNotEmpty()) {
              this.closedSuccessBox = false;
            }
          }, 300);
        },
        () => {
          this.$notification.error(this.$t('contract.not_loaded'));
        },
      );
    },
    confirmChangeDocument() {
      this.$router.push({
        name: 'createOwn',
        params: {
          workspace_id: this.$route.params.workspace_id,
          contract_id: this.$route.params.contract_id,
        },
      });
    },
    changeRadioValue(newValue) {
      this.contentOptionsChoice = newValue;
      this.activeContent = newValue;
    },
    changePartRadioValue(newValue) {
      this.contentOptionsChoice = newValue;
      $(this.activeElement).removeClass('undefined');
      $(this.activeElement).removeClass('show');
      $(this.activeElement).removeClass('hide');
      $(this.activeElement).addClass(newValue);
    },
    arePartiesFilled() {
      let proposersCount = 0;
      const parties = new Set();

      const isFillAbleSignIdentity = (signIdentity, contract) => {
        return !(!signIdentity.is_proposer && FeatureFlags.isUnilateralContract(contract));
      };

      for (let index in this.contractSignIdentities) {
        const signIdentity = this.contractSignIdentities[index];

        if (
          !signIdentity.email &&
          !signIdentity.variable_position &&
          isFillAbleSignIdentity(signIdentity, this.contract)
        ) {
          return false;
        }

        if (signIdentity.is_proposer) {
          proposersCount++;
        } else {
          parties.add(signIdentity.party_order);
        }
      }

      // dynamic version of this code is in Parties.analyzeParties that returns isIncomplete + backend PrepareDocsBeforeSend.validateContract
      return (
        proposersCount > 0 &&
        (this.contract.type !== 'form' || parties.size >= this.contract.template.analysis.minCounterpartiesCount)
      );
      // return ContractHelper.arePartiesFilled(this.contract);
    },
    recalculateElements(checkShowFinish = false) {
      const vm = this;
      let total = 0;
      let done = 0;
      let totalWithoutOptional = 0;
      let doneWithoutOptional = 0;

      let bindedPosition = false;

      $(document)
        .find('con-part')
        .each(function () {
          var $conPart = $(this);
          var conPartId = $conPart.attr('con-id');
          var conHideable = $conPart.attr('hideable');

          $(document)
            .find('con-section con-identity')
            .each(function () {
              const $section = $(this).closest('con-section');

              if (!bindedPosition) {
                bindedPosition = true;

                if ($section.hasClass('completed')) {
                  total += 1;
                  done += 1;
                } else {
                  total += 1;
                }

                if ($section.is('[filling-type=counterparty]')) {
                  $section.addClass('counterparty');
                  $(this).addClass('counterparty');
                  $section.find('con-option').addClass('counterparty');
                }

                if (!$section.is('[optional=true]') && !$(this).is('[filling-type=counterparty]')) {
                  if ($section.hasClass('completed')) {
                    totalWithoutOptional += 1;
                    doneWithoutOptional += 1;
                  } else {
                    totalWithoutOptional += 1;
                  }
                }
              }
            });

          let conPartVisible = vm.filterData(conPartId);

          if ('show' == conPartVisible.code || 'false' == conHideable) {
            $conPart.find('con-section').each(function () {
              const $section = $(this);

              if ($section.is('[filling-type=counterparty]')) {
                $section.addClass('counterparty');
                $(this).addClass('counterparty');
                $section.find('con-option').addClass('counterparty');
              }

              if ($section.hasClass('completed')) {
                total += 1;
                done += 1;
                if (!$section.is('[optional=true]') && !$(this).is('[filling-type=counterparty]')) {
                  totalWithoutOptional += 1;
                  doneWithoutOptional += 1;
                }
              } else {
                $section.find('con-text, con-textarea, con-number, con-date').each(function () {
                  const conOptions = $(this).closest('con-option').closest('con-options');
                  if (!conOptions.length) {
                    total += 1;

                    if ($(this).is('[filling-type=counterparty]')) {
                      $section.addClass('counterparty');
                      $(this).addClass('counterparty');
                      $section.find('con-option').addClass('counterparty');
                      conOptions.addClass('counterparty');
                    }

                    if ($(this).hasClass('edited')) {
                      done += 1;
                    }

                    if (!$(this).is('[optional=true]') && !$(this).is('[filling-type=counterparty]')) {
                      totalWithoutOptional += 1;
                    }

                    if (
                      !$(this).is('[optional=true]') &&
                      !$(this).is('[filling-type=counterparty]') &&
                      $(this).hasClass('edited')
                    ) {
                      doneWithoutOptional += 1;
                    }
                  }

                  if (conOptions.length && conOptions.hasClass('edited')) {
                    total += 1;

                    if ($(this).is('[filling-type=counterparty]')) {
                      $section.addClass('counterparty');
                      $(this).addClass('counterparty');
                      $section.find('con-option').addClass('counterparty');
                      conOptions.addClass('counterparty');
                    }

                    if ($(this).hasClass('edited')) {
                      done += 1;
                    }

                    if (!conOptions.is('[optional=true]') && !$(this).is('[filling-type=counterparty]')) {
                      totalWithoutOptional += 1;

                      if (
                        $(this).hasClass('edited') &&
                        !$(this).is('[optional=true]') &&
                        !$(this).is('[filling-type=counterparty]')
                      ) {
                        doneWithoutOptional += 1;
                      }
                    }
                  }
                });

                $section.find('con-options').each(function () {
                  const conOptions = $(this);

                  if ($(this).is('[filling-type=counterparty]')) {
                    $section.addClass('counterparty');
                    $(this).addClass('counterparty');
                    $section.find('con-option').addClass('counterparty');
                    conOptions.addClass('counterparty');
                  }

                  if (conOptions.hasClass('edited')) {
                    total += 1;
                    done += 1;
                    if (!conOptions.is('[optional=true]') && !$(this).is('[filling-type=counterparty]')) {
                      totalWithoutOptional += 1;
                      doneWithoutOptional += 1;
                    }
                  } else {
                    total += 1;
                    if (!conOptions.is('[optional=true]') && !$(this).is('[filling-type=counterparty]')) {
                      totalWithoutOptional += 1;
                    }
                  }
                });
              }
            });
          } else if (!conPartVisible) {
            if ($(this).is('[filling-type=counterparty]')) {
              $(this).addClass('counterparty');
            }

            total += 1;
            done += 0;

            if (!$conPart.is('[optional=true]') && !$(this).is('[filling-type=counterparty]')) {
              totalWithoutOptional += 1;
              doneWithoutOptional += 0;
            }
          }
        });

      if (total == done) {
        window.dataLayer.push({ event: 'contract_proposal_text_completed' });
      }

      vm.recalculatePart();

      vm.totalFields = 0;
      vm.total = 0 === total ? 1 : total;
      vm.totalWithoutOptional = totalWithoutOptional;
      vm.doneWithoutOptional = doneWithoutOptional;
      vm.done = done;

      if (checkShowFinish && vm.total === vm.done && 0 === vm.total) vm.showFinish = true;
    },
    closeOpenedContentType() {
      const elementsInEditingMode = document.getElementsByClassName('editing');

      for (let element of elementsInEditingMode) {
        element.classList.remove('editing');
      }

      this.contentType = '';
    },
    fetchJS() {
      const vm = this;
      vm.loaded = true;

      jQuery(document).ready(function ($) {
        setTimeout(function () {
          $('con-part').each(function () {
            const id = $(this).attr('con-id');
            var hideable = $(this).attr('hideable');
            var conPartVisible = vm.filterData(id);

            if (('true' == hideable && 'show' == conPartVisible.code) || 'true' == hideable) {
              $(this).addClass(String(conPartVisible.code));
            }

            if ('true' == hideable && ('hide' == conPartVisible.code || 'show' == conPartVisible.code)) {
              $(this).append('<div class="trash"><span class="icon icon-bin"></span></div>');
              $(this).addClass(String(conPartVisible.code));
            }
          });

          $('con-options').each(function () {
            const conOptions = $(this);
            const isNotAlternativeTextSelect = !!conOptions.attr('bind');
            if (isNotAlternativeTextSelect) {
              if ('counterparty' == conOptions.attr('filling-type')) {
                conOptions.addClass('counterparty');
              }

              const getSelectPlaceholder = () => {
                return conOptions.attr('placeholder') || conOptions.closest('con-section').attr('label') || 'Vyberte';
              };
              const notSelected = $(`<span class="not-selected">[${getSelectPlaceholder()}]</span>`);
              $(this).addClass('edited-select');
              $(this).append(notSelected);
            }

            const id = conOptions.attr('con-id');
            const content = vm.filterData(id);
            if (content) {
              var eddited = $('<div class="edited">' + content.code + '</div>');
              $(this).append(eddited);

              if ('counterparty' == conOptions.attr('filling-type')) {
                eddited.addClass('counterparty');
              }

              if ('position' == $(this).attr('bind')) {
                $(this)
                  .closest('con-section')
                  .append('<div class="trash position"><span class="icon icon-bin"></span></div>');
              } else if ('false' == $(this).closest('con-part').attr('hideable') && !$(this).is('[bind]')) {
                $(this)
                  .closest('con-section')
                  .append('<div class="trash section"><span class="icon icon-bin"></span></div>');
              }

              $(this).addClass('edited');
            } else {
              $(this).attr('edited', false);
              // $(this).closest('con-section').find('.trash').remove();
              $(this).removeClass('edited');
            }
          });

          $('con-text,con-textarea,con-number,con-number,con-date').each(function () {
            const input = $(this);
            const label = input.attr('label');
            const id = input.attr('con-id');
            const content = vm.filterData(id);

            if (content) {
              if (0 === content.code.trim().length) {
                input.html(label).addClass('skipped');
              } else {
                input.html(content.code);
              }
              input.addClass('edited');
            } else {
              input.removeClass('edited').addClass('skipped').html(label);
            }
          });

          setTimeout(function () {
            vm.checkSections();
          }, 1);
        }, 100);

        function countTopPosition(element) {
          if ($(document).find('.content-box').length) {
            const mainBox = $(document).find('.content-box').offset().top;

            var top = $(document).find(element).offset().top - mainBox - 30 - $(document).find(element).height();

            if (top > $(document).find('.content-box').height()) {
              return 0;
            } else {
              return top;
            }
          }
        }

        function countSimpleTopPosition(element) {
          if ($(document).find('.content-box').length) {
            const mainBox = $(document).find('.content-box').offset().top;
            return $(document).find(element).offset().top - mainBox - 30;
          }
        }

        $(document).on('click', '.trash', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if (vm.elementValueChanged(vm.activeElement, e) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput(vm.activeElement);
            return;
          }

          // vm.contentOptionsChoice = 'show';
          // vm.activeElementTitle = $(this).attr('name');
          // vm.activeElementDescription = $(this).attr('description');
          // vm.activeContent = '';
          vm.activeElement = $(this);
          vm.contentType = 'trash';
          vm.contentId = $(this).attr('con-id');
          vm.contentTop = countSimpleTopPosition($(this));

          if ($(this).hasClass('section')) {
            $(this).closest('con-section').addClass('editing');
          } else {
            $(this).closest('con-part').addClass('editing');
          }
        });

        $(document).on('click', 'con-options.edited-select.edited > div', function () {
          const element = $(this);
          const options = element.closest('con-options');

          const this_options = options;

          vm.contentOptions = [];
          this_options.find('con-option').each(function () {
            var this_option = $(this);
            vm.contentOptions.push({
              label: this_option.attr('label'),
              value: this_option.html(),
            });
          });

          if ('position' == this_options.attr('bind')) {
            const currentId = this_options.attr('con-id');

            $("[bind='position']").each(function () {
              if ($(this).attr('con-id') != currentId) {
                vm.contentBind = $(this);
              }
            });
          }

          vm.contentOptionsChoice = '';
          vm.activeElementTitle = this_options.attr('name');
          vm.activeElementDescription = this_options.attr('description');
          vm.activeContent = '';
          vm.activeElement = this_options;
          vm.contentType = 'options';
          vm.contentId = this_options.attr('con-id');
          vm.contentTop = countTopPosition(this_options);
          vm.activeElement[0].classList.add('selected');

          if ('position' == $(vm.activeElement).attr('bind')) {
            $("[bind='position']").each(function () {
              $(this).addClass('editing');
            });
          }
        });

        // There is no difference between usage of con-text and con-textarea, in fact variable contentType for "con-text" is set to "con-textarea", so probably dead code, but kept in place for compatibility with older templates. Up to decide what to do with it during refactoring

        $(document).on('click', 'con-text', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if (vm.elementValueChanged(vm.activeElement) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput([e.target]).then(() => {
              vm.focusConInput(vm.activeElement[0]);
            });
            return;
          }

          vm.activeElementTitle = $(this).attr('name');
          vm.activeElementDescription = $(this).attr('description');
          vm.activeElementPlaceholder = $(this).attr('placeholder');

          if ($(this).hasClass('edited') && !$(this).hasClass('skipped')) {
            // vm.activeContent = $(this).html();
            if (vm.activeContent instanceof Editor) {
              vm.activeContent.destroy();
              vm.activeContent = null;
            }
            vm.activeContent = new Editor({
              content: $(this).html(),
              extensions: [
                new HardBreak(),
                new Bold(),
                new Italic(),
                new Underline(),
                new Placeholder({
                  emptyNodeClass: 'is-empty',
                  emptyNodeText: vm.activeElementPlaceholder,
                  showOnlyWhenEditable: true,
                }),
              ],
              onUpdate: (editor) => {
                let content = editor.getHTML();
                content = content.replace('<p>', '').replace('</p>', '');
                if (content.length > 0) {
                  $(vm.activeElement).html(content);
                } else {
                  $(vm.activeElement).html($(vm.activeElement).attr('label'));
                }
              },
            });
          } else {
            if (vm.activeContent instanceof Editor) {
              vm.activeContent.destroy();
              vm.activeContent = null;
            }

            vm.activeContent = new Editor({
              content: '',
              extensions: [
                new HardBreak(),
                new Bold(),
                new Italic(),
                new Underline(),
                new Placeholder({
                  emptyNodeClass: 'is-empty',
                  emptyNodeText: vm.activeElementPlaceholder,
                  showOnlyWhenEditable: true,
                }),
              ],
              onUpdate: (editor) => {
                let content = editor.getHTML();
                content = content.replace('<p>', '').replace('</p>', '');
                if (content.length > 0) {
                  $(vm.activeElement).html(content);
                } else {
                  $(vm.activeElement).html($(vm.activeElement).attr('label'));
                }
              },
            });
          }

          vm.activeElement = $(this);
          vm.contentType = 'textarea';
          vm.contentId = $(this).attr('con-id');
          vm.contentTop = countTopPosition($(this));
          vm.activeElement[0].classList.add('selected');
          vm.focusConInput(vm.activeElement[0]);
        });

        $(document).on('click', 'con-textarea', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if (vm.elementValueChanged(vm.activeElement) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput([e.target]).then(() => {
              vm.focusConInput(vm.activeElement[0]);
            });
            return;
          }

          vm.activeElementTitle = $(this).attr('name');
          vm.activeElementDescription = $(this).attr('description');
          vm.activeElementPlaceholder = $(this).attr('placeholder');

          if ($(this).hasClass('edited') && !$(this).hasClass('skipped')) {
            // vm.activeContent = $(this).html();
            if (vm.activeContent instanceof Editor) {
              vm.activeContent.destroy();
              vm.activeContent = null;
            }
            vm.activeContent = new Editor({
              content: $(this).html(),
              extensions: [
                new HardBreak(),
                new Bold(),
                new Italic(),
                new Underline(),
                new Placeholder({
                  emptyNodeClass: 'is-empty',
                  emptyNodeText: vm.activeElementPlaceholder,
                  showOnlyWhenEditable: true,
                }),
              ],
              onUpdate: (editor) => {
                let content = editor.getHTML();
                content = content.replace('<p>', '').replace('</p>', '');
                if (content.length > 0) {
                  $(vm.activeElement).html(content);
                } else {
                  $(vm.activeElement).html($(vm.activeElement).attr('label'));
                }
              },
            });
          } else {
            if (vm.activeContent instanceof Editor) {
              vm.activeContent.destroy();
              vm.activeContent = null;
            }

            vm.activeContent = new Editor({
              content: '',
              extensions: [
                new HardBreak(),
                new Bold(),
                new Italic(),
                new Underline(),
                new Placeholder({
                  emptyNodeClass: 'is-empty',
                  emptyNodeText: vm.activeElementPlaceholder,
                  showOnlyWhenEditable: true,
                }),
              ],
              onUpdate: (editor) => {
                let content = editor.getHTML();
                content = content.replace('<p>', '').replace('</p>', '');
                if (content.length > 0) {
                  $(vm.activeElement).html(content);
                } else {
                  $(vm.activeElement).html($(vm.activeElement).attr('label'));
                }
              },
            });
          }

          vm.activeElement = $(this);
          vm.contentType = 'textarea';
          vm.contentId = $(this).attr('con-id');
          vm.contentTop = countTopPosition($(this));
          vm.activeElement[0].classList.add('selected');
          vm.focusConInput(vm.activeElement[0]);
        });

        $(document).on('click', 'con-date', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if (e.target.innerHTML === vm.activeContent) {
            vm.focusConInput(vm.activeElement[0]);
            return;
          }

          if (vm.elementValueChanged(vm.activeElement) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput([e.target]).then(() => {
              vm.focusConInput(vm.activeElement[0]);
            });
            return;
          }

          vm.activeElementTitle = $(this).attr('name');
          vm.activeElementDescription = $(this).attr('description');
          vm.activeElementPlaceholder = $(this).attr('placeholder');

          const parseDateRegex = /^\s*(3[01]|[12][0-9]|0?[1-9])\.\s?(1[012]|0?[1-9])\.\s?((?:19|20)\d{2})\s*$/;
          let date = null;

          let groups = e.target.innerHTML.trim().match(parseDateRegex);
          if (groups && 4 === groups.length) {
            date = new Date(`${groups[3]}/${groups[2]}/${groups[1]}`);
          } else {
            date = new Date();
          }

          vm.activeContent = date.getDate() + '. ' + (date.getMonth() + 1) + '. ' + date.getFullYear();
          vm.selectedDate = date;

          vm.focusConInput(this);

          vm.activeElement = $(this);
          vm.contentType = 'date';
          vm.contentId = $(this).attr('con-id');
          vm.contentTop = countTopPosition($(this));
          vm.activeElement[0].classList.add('selected');
        });

        $(document).on('click', 'con-number', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if (vm.elementValueChanged(vm.activeElement) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput([e.target]).then(() => {
              vm.focusConInput(vm.activeElement[0]);
            });
            return;
          }

          vm.activeElementTitle = $(this).attr('name');
          vm.activeElementDescription = $(this).attr('description');
          vm.activeElementPlaceholder = $(this).attr('placeholder');

          if ($(this).hasClass('edited')) {
            vm.activeContent = $(this).html();
          } else {
            vm.activeContent = '';
          }

          vm.activeElement = $(this);
          vm.contentType = 'number';
          vm.contentId = $(this).attr('con-id');
          vm.contentTop = countTopPosition($(this));
          vm.activeElement[0].classList.add('selected');
          vm.focusConInput(vm.activeElement[0]);
        });

        $(document).on('click', 'con-options', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if (vm.elementValueChanged(vm.activeElement) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput([e.target]).then(() => {
              vm.focusConInput(vm.activeElement[0]);
            });
            return;
          }

          const this_options = $(this);

          vm.contentOptions = [];
          $(this)
            .children('con-option')
            .each(function () {
              var this_option = $(this);
              vm.contentOptions.push({
                label: this_option.attr('label'),
                value: this_option.html(),
              });
            });

          if (!this_options.hasClass('edited')) {
            if ('position' == this_options.attr('bind')) {
              const currentId = this_options.attr('con-id');

              $("[bind='position']").each(function () {
                if ($(this).attr('con-id') != currentId) {
                  vm.contentBind = $(this);
                }
              });
            }

            vm.contentOptionsChoice = '';
            vm.activeElementTitle = this_options.attr('name');
            vm.activeElementDescription = this_options.attr('description');
            vm.activeContent = '';
            vm.activeElement = this_options;
            vm.contentType = 'options';
            vm.contentId = this_options.attr('con-id');
            vm.contentTop = countTopPosition(this_options);
            vm.activeElement[0].classList.add('selected');

            if ('position' == $(vm.activeElement).attr('bind')) {
              $("[bind='position']").each(function () {
                $(this).addClass('editing');
              });
            }
          }
        });

        $(document).on('click', 'con-part', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if (vm.elementValueChanged(vm.activeElement) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput([e.target]);
            return;
          }

          if ($(this).hasClass('undefined') || $(this).hasClass('hide')) {
            e.preventDefault();

            vm.contentOptionsChoice = 'show';
            vm.activeElementTitle = $(this).attr('name');
            vm.activeElementDescription = $(this).attr('description');
            vm.activeContent = '';
            vm.activeElement = $(this);
            vm.contentType = 'part';
            vm.contentId = $(this).attr('con-id');
            vm.contentTop = countSimpleTopPosition($(this));
            vm.activeElement[0].classList.add('selected');

            $(this).addClass('editing');
          }
        });
      });
    },
    checkSections() {
      const vm = this;

      jQuery(document).ready(function ($) {
        $(document)
          .find('con-part')
          .each(function () {
            $(document)
              .find('con-section con-identity')
              .each(function () {
                var ready = false;
                var $section = $(this).closest('con-section');

                $section.find('con-identity').each(function () {
                  $section.addClass('hasIdentity');
                });

                $section.find('con-options').each(function () {
                  $section.addClass('hasOptions');

                  var $conOptions = $(this);

                  if ($conOptions.hasClass('edited')) {
                    ready = true;
                  } else {
                    ready = false;
                  }
                });

                if (ready) {
                  $section.addClass('completed');
                } else {
                  $section.removeClass('completed');
                }
              });

            var $conPart = $(this);
            var conPartId = $conPart.attr('con-id');
            var conHideable = $conPart.attr('hideable');
            var conPartVisible = vm.filterData(conPartId);

            if ('show' == conPartVisible.code || 'false' == conHideable) {
              $conPart.find('con-section').each(function () {
                var ready = true;
                var $section = $(this);

                $section.find('con-textarea, con-text, con-input, con-number, con-date').each(function () {
                  if (!$(this).closest('option').length && $(this).is(':visible')) {
                    if ($(this).hasClass('edited')) {
                      ready &= true;
                    } else {
                      ready &= false;
                    }
                  }
                });

                $section.find('con-identity').each(function () {
                  $section.addClass('hasIdentity');
                });

                let allOptions = [...$section.find('con-options')].reverse();

                allOptions = allOptions
                  .filter((item, index, array) => {
                    return (
                      index ===
                      array.findIndex(
                        (comparedItem) => comparedItem.attributes['con-id'].value === item.attributes['con-id'].value,
                      )
                    );
                  })
                  .reverse();

                const filteredOptions = ((nodesInArray) => {
                  const codeFragment = document.createDocumentFragment();
                  nodesInArray.forEach((item) => {
                    codeFragment.appendChild(item.cloneNode());
                  });

                  return codeFragment.childNodes;
                })(allOptions);

                $.each(filteredOptions, function () {
                  $section.addClass('hasOptions');

                  var $conOptions = $(this);

                  if ($conOptions.hasClass('edited')) {
                    ready &= true;
                  } else {
                    ready &= false;
                  }

                  $conOptions
                    .find('div.edited')
                    .find('con-textarea, con-text, con-input, con-number, con-date')
                    .each(function () {
                      if ($(this).hasClass('edited')) {
                        ready &= true;
                      } else {
                        ready &= false;
                      }
                    });
                });

                if (ready) {
                  $section.addClass('completed');
                } else {
                  $section.removeClass('completed');
                }
              });
            }
          });

        vm.recalculateElements(true);
      });
    },
    recalculatePart() {
      const vm = this;

      jQuery(document).ready(function ($) {
        var titleIndex = 0;

        $(document)
          .find('con-part')
          .each(function () {
            var $conPart = $(this);
            var conPartId = $conPart.attr('con-id');
            var hideable = $conPart.attr('hideable');
            var conPartVisible = vm.filterData(conPartId);

            if ('show' == conPartVisible.code || 'false' == hideable || !hideable) {
              titleIndex = titleIndex + 1;
              $conPart.find('con-no[con-part-id="' + conPartId + '"]').each(function () {
                $(this).text(titleIndex);
              });
            }
          });
      });
    },
    isActiveElementRequired() {
      return !this.isActiveElementOptional();
    },
    isActiveElementOptional() {
      const counterparty = this.activeElement[0].attributes.getNamedItem('filling-type');
      const optional = this.activeElement[0].attributes.getNamedItem('optional');
      return (
        (optional && 'true' === optional.nodeValue) || (counterparty && 'counterparty' === counterparty?.nodeValue)
      );
    },
    saveContentType(e) {
      const isResetAction = !!e && 'reset' === e.type;
      if (this.activeContent instanceof Editor) {
        const content = this.activeContent.getHTML().replace('<p>', '').replace('</p>', '');
        if (0 === content.trim().length && this.isActiveElementRequired()) {
          this.$notification.warning(this.$t('contract.form.required_save'));
          return;
        }
      } else {
        if (this.activeElement) {
          const conSection = this.activeElement.parents('con-section');
          if (conSection && conSection.hasClass('hasIdentity')) {
            window.dataLayer.push({ event: 'contract_proposal_header_completed' });
          }
        }
      }
      if ('' === this.activeContent && this.isActiveElementRequired()) {
        this.$notification.warning(this.$t('contract.form.required_save'));
      }

      const vm = this;

      jQuery(document).ready(function ($) {
        const $activeElement = $(vm.activeElement);

        let eddited;

        if (isResetAction) {
          vm.activeContent = '';
          $activeElement.addClass('skipped');
        }

        if ('date' === vm.contentType) {
          vm.activeContent = getFormattedDate({
            date: vm.choiceDate,
            format: 'DD.MM.YYYY',
          });
        }

        if ('options' == vm.contentType) {
          $activeElement.find('div.edited').remove();

          eddited = $('<div class="edited">' + vm.activeContent + '</div>');
          $activeElement.append(eddited);

          if ('counterparty' == $activeElement.attr('filling-type')) {
            eddited.addClass('counterparty');
          }

          if ('position' == $activeElement.attr('bind')) {
            if (!$activeElement.is('[bind]')) {
              $("[bind='position']").each(function () {
                $(this)
                  .closest('con-section')
                  .append('<div class="trash position"><span class="icon icon-bin"></span></div>');
              });
            }
          } else if ('false' == $activeElement.closest('con-part').attr('hideable')) {
            if (!$activeElement.is('[bind]')) {
              $activeElement
                .closest('con-section')
                .append('<div class="trash section"><span class="icon icon-bin"></span></div>');
            }
          } else if ($activeElement.closest('con-part').find('.trash').length < 1) {
            if (!$activeElement.is('[bind]')) {
              $activeElement.closest('con-part').append('<div class="trash"><span class="icon icon-bin"></span></div>');
            }
          }

          $activeElement.addClass('edited');
        } else {
          if (vm.activeContent instanceof Editor) {
            vm.activeContent = vm.activeContent.getHTML();
            vm.activeContent = vm.activeContent.replace('<p>', '').replace('</p>', '');
          }
          if (vm.activeContent.trim().length > 0) {
            $activeElement.html(vm.activeContent).addClass('edited').removeClass('skipped');
          } else {
            $activeElement.html($activeElement.attr('label'));
            if (isResetAction) {
              $activeElement.addClass('edited');
            } else {
              $activeElement.removeClass('edited');
            }
          }
        }

        var data_item;

        if (vm.contentBind) {
          for (var i = 0; i < vm.contentOptions.length; i++) {
            if (vm.contentOptions[i].value != vm.activeContent) {
              $(vm.contentBind).addClass('edited');
              $(vm.contentBind).find('.edited').remove();
              $(vm.contentBind).closest('con-section').find('.trash').remove();

              eddited = $('<div class="edited">' + vm.contentOptions[i].value + '</div>');
              if (e.type !== 'reset') {
                $(vm.contentBind).append(eddited);
              }
              $(vm.contentBind)
                .closest('con-section')
                .append('<div class="trash position"><span class="icon icon-bin"></span></div>');

              data_item = vm.filterData(vm.contentBind.attr('con-id'));

              if (data_item.length) {
                data_item.code = isResetAction ? '' : vm.contentOptions[i].value;
              } else {
                vm.data.push({
                  id: vm.contentBind.attr('con-id'),
                  code: isResetAction ? '' : vm.contentOptions[i].value,
                });
              }
            }
          }
        }

        data_item = vm.filterData(vm.contentId);

        if (data_item.id) {
          data_item.code = isResetAction ? '' : vm.activeContent;
        } else {
          vm.data.push({
            id: vm.contentId,
            code: isResetAction ? '' : vm.activeContent,
          });
        }

        vm.contentBind = false;
        vm.saveTemplateAnswers();
        vm.checkSections();
        vm.recalculateElements();
        vm.contentType = '';
        vm.selectedDate = new Date();

        $('con-section').each(function () {
          $(this).removeClass('editing');
        });
        if (vm.autoFocusNextInput) {
          if (!vm.contentFilled) {
            vm.focusNextInput();
          } else {
            vm.scrollToConInput([document.querySelector('html')]);
          }
        }
      });
    },
    savePartContentType() {
      const vm = this;

      jQuery(document).ready(function ($) {
        $(vm.activeElement).append('<div class="trash"><span class="icon icon-bin"></span></div>');

        $(vm.activeElement).removeClass('editing');
        $(vm.activeElement).removeClass('undefined');
        $(vm.activeElement).removeClass('show');
        $(vm.activeElement).removeClass('hide');
        $(vm.activeElement).addClass(vm.contentOptionsChoice);

        vm.saveToData(vm.contentId, vm.contentOptionsChoice);
        vm.saveTemplateAnswers();
        vm.checkSections();
        vm.recalculateElements();
        vm.contentType = '';
      });
    },
    deletePart() {
      const vm = this;
      var _that = $(this.activeElement);
      var $conPart;
      var $conSection;
      var conSectionId;

      if (_that.hasClass('section')) {
        $conPart = $(_that).closest('con-part');
        $conSection = _that.closest('con-section');

        $conSection.removeClass('completed');
        conSectionId = $conSection.find('con-options').attr('con-id');
        vm.deleteFromData(conSectionId);

        $conSection.find('con-options').removeClass('edited');
        $conSection.find('con-options').removeClass('completed');
        $conSection.find('div.edited').remove();
        $conSection.find('.trash').remove();

        $conSection.removeClass('editing');
      } else {
        $conPart = $(_that).closest('con-part');
        $conSection = $conPart.find('con-section');

        $conPart.find('.trash').remove();
        $conPart.removeClass('editing');
        $conPart.removeClass('show');
        $conPart.removeClass('hide');
        $conPart.addClass('undefined');

        $conSection.removeClass('completed');

        vm.deleteFromData($conPart.attr('con-id'));

        conSectionId = $conSection.find('con-options').attr('con-id');

        vm.deleteFromData(conSectionId);

        $conSection.find('con-options').removeClass('edited');
        $conSection.find('con-options').removeClass('completed');
        $conSection.find('div.edited').remove();
        $conSection.find('.trash').remove();

        if ($conSection.hasClass('hasIdentity') || _that.hasClass('position')) {
          $(document)
            .find("[bind='position']")
            .each(function () {
              var _that = $(this);

              $(this).closest('con-section').removeClass('editing');

              var binded = $(this);
              var bindedId = binded.attr('con-id');

              vm.deleteFromData(bindedId);

              _that.removeClass('edited');
              _that.removeClass('completed');
              _that.find('div.edited').remove();
              _that.closest('con-section').find('.trash').remove();
              _that.closest('con-section').removeClass('completed');

              vm.saveTemplateAnswers();
            });
        }
      }

      vm.saveTemplateAnswers();
      vm.recalculateElements();
      vm.contentType = '';
      vm.focusNextInput();
    },
    cancelDeletePart() {
      const vm = this;
      $(vm.activeElement).closest('con-part').removeClass('editing');
      vm.checkSections();
      vm.recalculateElements();
      vm.contentType = '';
      vm.focusNextInput();
    },
    saveToData(id, value) {
      let data_item = this.filterData(id);

      if (data_item.id) {
        data_item.code = value;
      } else {
        this.data.push({
          id: id,
          code: value,
        });
      }
    },
    filterData(searchId) {
      if (this.data.length) {
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].id == searchId) {
            return this.data[i];
          }
        }
      }

      return false;
    },
    saveTemplateAnswers() {
      const data = {
        answers: JSON.stringify(this.data),
      };
      ContractService.update(this.$route.params.contract_id, data).then(
        () => {
          this.contract.answers = data.answers; // probably dead code, answers are loaded only in fetchData
        },
        () => {},
      );
    },
    checkModal() {
      const windowWidth = this.$vuetify.breakpoint.width;

      this.showModal = windowWidth < 991;
      this.modalClose = windowWidth > 991;
      this.showFinish = windowWidth > 991;
      this.closedSuccessBox = windowWidth < 991;
    },
    confirmBeforeSend(signMethod) {
      if (!this.areContractRolesConsistent) {
        return this.$notification.error(this.$t('contract.positions.inconsistent'));
      } else if (signaturesHelper.isNotEmpty()) {
        return signMethod();
      }
      const vm = this;
      vm.$dialog.confirm(vm.$t('contract.positions.empty')).then(function () {
        signMethod();
      });
    },
    addAttachment() {
      this.attachmentPanelOpen = true;
      window.dataLayer.push({ event: 'contract_proposal_attachment' });
    },
    deleteFromData(id) {
      var index = 'noindex';

      for (var i = 0; i < this.data.length; i++) {
        if (this.data[i].id == id) {
          index = i;
        }
      }

      if (index != 'noindex') {
        this.data.splice(index, 1);
      }
    },
    openSignaturePositionsModal() {
      this.isSignaturePositionsModalOpened = true;
    },
    closeSignaturePositionsModal() {
      this.isSignaturePositionsModalOpened = false;
    },
    loadPositionsFromHistory(allPositions) {
      signaturesHelper.replaceAll((upsert) => {
        let partySignOrder = 0;
        let lastPartyOrder = null;
        const processedPositions = new Set();

        for (let i = 0; i < this.contractSignIdentities.length; i++) {
          const signIdentity = this.contractSignIdentities[i];
          const uniquePartyOrder = `${signIdentity.is_proposer ? 'P' : 'C'}${signIdentity.party_order}`;

          if (lastPartyOrder !== uniquePartyOrder) {
            lastPartyOrder = uniquePartyOrder;
            partySignOrder = 0;
          }

          const signPositions = _.filter(
            allPositions,
            (position) =>
              !processedPositions.has(`${position.offset_left},${position.offset_top}`) &&
              position.is_proposer === signIdentity.is_proposer &&
              position.party_order === signIdentity.party_order &&
              position.party_sign_order === partySignOrder,
          );

          signPositions.forEach((position) => {
            processedPositions.add(`${position.offset_left},${position.offset_top}`);
          });

          upsert(signIdentity, signPositions);
          partySignOrder++;
        }
      });

      this.savePositionsInBackend();
    },
    savePositionsInBackend(onSuccess) {
      if (this.swappingSignatures) {
        return;
      }

      const signIdentities = {};
      _.each(this.contractSignatures, (signature) => {
        const positions = [];

        signaturesHelper.findSignatures(signature.signIdentity)?.forEach((placedSignature) => {
          const signatureHeight = placedSignature.relativeWidth * (149 / 323);

          if (placedSignature.page < 0) {
            placedSignature.page = 0;
          }
          if (placedSignature.top <= 2) {
            placedSignature.top = 2;
          }
          if (placedSignature.left <= 2) {
            placedSignature.left = 2;
          }
          if (placedSignature.top >= 100 - signatureHeight) {
            placedSignature.top = 100 - signatureHeight;
          }
          if (placedSignature.left >= 100 - placedSignature.relativeWidth - 2) {
            placedSignature.left = 100 - placedSignature.relativeWidth - 2;
          }

          positions.push({
            uuid: placedSignature.uuid,
            anchor: placedSignature.anchor,
            page: placedSignature.page,
            offsetTop: placedSignature.top,
            offsetLeft: placedSignature.left,
            showHeader:
              'undefined' === typeof placedSignature.show_header
                ? !this.isDefaultSignHeaderIgnored
                : placedSignature.show_header,
            showFooter:
              'undefined' === typeof placedSignature.show_footer
                ? !this.isDefaultSignFooterIgnored
                : placedSignature.show_footer,
            relativeWidth: placedSignature.relativeWidth || this.workspacePlaceholderWidth,
          });
        });

        signIdentities[signature.signIdentity.id] = positions;
      });

      this.saveSignaturesPlaceholdersPositions(this.contract.id, signIdentities);

      ContractService.saveSignPositions(this.contract.id, signIdentities)
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(() => {
          this.$notification.error(this.$t('general.save_error'));
        });
    },
    onSwapIdentities(e) {
      this.swappingSignatures = true;

      const signIdentities = {};

      _.each(this.contractSignatures, (signature) => {
        const positions = [];
        signaturesHelper.findSignatures(signature.signIdentity).forEach((placedSignature) => {
          const signatureHeight = placedSignature.relativeWidth * (149 / 323);

          if (placedSignature.page < 0) {
            placedSignature.page = 0;
          }
          if (placedSignature.top <= 2) {
            placedSignature.top = 2;
          }
          if (placedSignature.left <= 2) {
            placedSignature.left = 2;
          }
          if (placedSignature.top >= 100 - signatureHeight) {
            placedSignature.top = 100 - signatureHeight;
          }
          if (placedSignature.left >= 100 - placedSignature.relativeWidth - 2) {
            placedSignature.left = 100 - placedSignature.relativeWidth - 2;
          }

          positions.push({
            uuid: placedSignature.uuid,
            anchor: placedSignature.anchor,
            page: placedSignature.page,
            offsetTop: placedSignature.top,
            offsetLeft: placedSignature.left,
            showHeader:
              'undefined' === typeof placedSignature.show_header
                ? !this.isDefaultSignHeaderIgnored
                : placedSignature.show_header,
            showFooter:
              'undefined' === typeof placedSignature.show_footer
                ? !this.isDefaultSignFooterIgnored
                : placedSignature.show_footer,
            relativeWidth: placedSignature.relativeWidth || this.workspacePlaceholderWidth,
          });
        });

        signIdentities[signature.signIdentity.id] = positions;
      });

      const temporaryPositions = [...signIdentities[e.from]];

      signIdentities[e.from] = [...signIdentities[e.to]];
      signIdentities[e.to] = [...temporaryPositions];

      ContractService.saveSignPositions(this.contract.id, signIdentities)
        .then(() => {
          window.location.reload();
          this.swappingSignatures = false;
        })
        .catch(() => {
          this.$notification.error(this.$t('general.save_error'));
        });
    },
    setReadyToSend() {
      this.closedSuccessBox = false;
    },
    closeAndSave() {
      this.$notification.success(this.$t('contract.save.ok'));
      this.$router.push({
        name: 'overviewContractsActive',
        params: {
          workspace_id: this.contract.workspace_id,
        },
      });
    },
    blurSignature(signature) {
      if ('form' === this.contract.type) {
        return;
      }

      this.focusedSignatures = [...this.focusedSignatures.filter((signatureId) => signatureId !== signature)];
    },
    deleteSignature(signature, save = true) {
      const data = {
        anchor: signature.anchor,
        uniqueSignId: signature.uniqueSignId,
        page: null,
        offset_top: null,
        offset_left: null,
        show_header: null,
        show_footer: null,
      };
      signaturesHelper.upsert(signature.signIdentity, [data]);
      if (save) {
        this.savePositionsInBackend();
      }
    },
    signDragStartTouch(event) {
      this.signDragStart(event, true);
    },
    signDragStart(event, touch = false) {
      event.preventDefault();
      event.stopPropagation();
      if (this.firstDragPositions) {
        this.stopSignatureDrag({ event });
        return;
      }

      const clientX = event.clientX ? event.clientX : event.changedTouches ? event.changedTouches[0].clientX : 0;
      const clientY = event.clientY ? event.clientY : event.changedTouches ? event.changedTouches[0].clientY : 0;

      this.firstDragPositions = {
        top: clientY,
        left: clientX,
      };

      if (touch) {
        this.disableWindowScroll();
      }
    },
    signDrag(event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.draggingSignature) {
        const $sign = document.querySelector('.sign-placement[data-id="' + this.draggingSignature.uniqueSignId + '"]');

        const clientX = event.clientX ? event.clientX : event.changedTouches ? event.changedTouches[0].clientX : 0;
        const clientY = event.clientY ? event.clientY : event.changedTouches ? event.changedTouches[0].clientY : 0;

        $sign.style.top = $sign.offsetTop - (this.firstDragPositions.top - clientY) + 'px';
        $sign.style.left = $sign.offsetLeft - (this.firstDragPositions.left - clientX) + 'px';

        this.firstDragPositions.top -= this.firstDragPositions.top - clientY;
        this.firstDragPositions.left -= this.firstDragPositions.left - clientX;
      }
    },
    startSignatureDrag(signature) {
      // draggingSignature watcher registers event listeners
      this.draggingSignature = signature;
    },
    stopSignatureDrag({ event, height: parentHeight }) {
      if (!this.draggingSignature) {
        return; // prevent errors after forcedStop from signDragStart (TypeError: Cannot read property 'page' of null)
      }
      const rect = event.target.getBoundingClientRect();

      const x_ = (event.clientX || event.changedTouches[0].clientX || event.x) - rect.left; //x position within the element.
      const y_ = (event.clientY || event.changedTouches[0].clientY || event.y) - rect.top; //y position within the element.

      const trim = parentHeight - rect.height - 1;
      const $showImages = document.querySelector('.showImages');
      const $page = document.querySelector('.page[data-page-index="' + this.draggingSignature.page + '"]');
      const pageX = event.pageX ? event.pageX : event.changedTouches ? event.changedTouches[0].pageX : 0;
      const pageY = event.pageY ? event.pageY : event.changedTouches ? event.changedTouches[0].pageY : 0;
      const pagePreviews = document.querySelectorAll('div[data-page-index]');

      let pageNumber = this.draggingSignature.page;
      let divTop = $(document).find('.showImages').offset().top;
      let divLeft = $(document).find('.showImages').offset().left;
      let x = pageX - $page.offsetLeft;
      let y = pageY - $page.offsetTop;

      y = y - divTop + $($page).height() * pageNumber;
      x = x - divLeft;

      const leftCursorOffset = Math.round(x_) + 1;
      const topCursorOffset = Math.round(-y_) - trim;

      let leftPercentage = ((x - leftCursorOffset) / $($page).width()) * 100;
      leftPercentage = Math.min(leftPercentage, 100);
      leftPercentage = Math.max(leftPercentage, 0);

      let percentageToPage = ((y + topCursorOffset - $($page).height() * pageNumber) / $($page).height()) * 100;

      const signatureHeight = this.draggingSignature.relativeWidth * (149 / 323);

      if (percentageToPage >= 100) {
        if (pagePreviews?.length > pageNumber + 1) {
          pageNumber++;
          percentageToPage -= 100;
        }
      } else if (percentageToPage < 0) {
        if (pageNumber > 0) {
          pageNumber--;
          percentageToPage += 100;
        }
      }

      if (percentageToPage <= 2) {
        percentageToPage = 2;
      }
      if (leftPercentage <= 2) {
        leftPercentage = 2;
      }
      if (percentageToPage >= 100 - signatureHeight) {
        percentageToPage = 100 - signatureHeight;
      }
      if (leftPercentage >= 100 - this.draggingSignature.relativeWidth - 2) {
        leftPercentage = 100 - this.draggingSignature.relativeWidth - 2;
      }

      const data = {
        uuid: this.draggingSignature?.uuid,
        anchor: this.draggingSignature?.anchor || null,
        uniqueSignId: this.draggingSignature.uniqueSignId,
        page: pageNumber,
        offset_top: percentageToPage,
        offset_left: leftPercentage,
        show_header: this.draggingSignature.show_header,
        show_footer: this.draggingSignature.show_footer,
        relative_width: this.draggingSignature.relativeWidth > 90 ? 90 : this.draggingSignature.relativeWidth,
      };

      signaturesHelper.upsert(this.draggingSignature.signIdentity, [data]);
      this.savePositionsInBackend();

      $showImages.removeEventListener('touchstart', this.signDragStart, false);
      $showImages.removeEventListener('touchmove', this.signDrag, false);
      $showImages.removeEventListener('mousedown', this.signDragStart, false);
      $showImages.removeEventListener('mousemove', this.signDrag, false);

      this.draggingSignature = null;
      this.firstDragPositions = null;

      this.enableWindowScroll();
    },
    disableWindowScroll() {
      const $nodes = $('body, html');
      $nodes.css({
        overflow: 'hidden',
      });
      document.body.addEventListener('touchmove', this.preventDefault, { passive: false });
    },
    enableWindowScroll() {
      const $nodes = $('body, html');
      $nodes.css({
        overflow: '',
      });
      document.body.removeEventListener('touchmove', this.preventDefault);
    },
    preventDefault(e) {
      e.preventDefault();
    },
    saveAndGoToNextElement() {
      this.saveContentType();
    },
    focusConInput(element) {
      this.$nextTick(() => {
        setTimeout(() => {
          switch (true) {
            case 'con-textarea' === element.localName:
              $('.ProseMirror').focus();
              break;
            case 'con-date' === element.localName:
              this.$nextTick(() => {
                if (this.$refs.datePickerRef && !this.$refs.datePickerRef.isShown) {
                  this.$refs.datePickerRef.$data.date = this.selectedDate;
                  $('.datetime-picker__button').click();
                }
              });
              break;
            case 'con-number' === element.localName:
              $('input.input__data[type=number]').focus();
              break;
          }
        }, 200);
      });
    },
    elementValueChanged(activeElement, trashEvent = null) {
      if (!activeElement) return false;
      if (this.isActiveElementOptional()) return false;
      if (trashEvent) {
        return true;
      }

      if ('con-options' === activeElement[0].localName) return false;
      if ('div' === activeElement[0].localName && null === activeElement[0].getAttribute('con-id')) return false;

      const elementConId = activeElement[0].getAttribute('con-id');
      const elementInnerHTML = activeElement[0].innerHTML;
      const elementDefaultLabel = activeElement[0].getAttribute('label');
      const elementData = this.data.filter((el) => el.id === elementConId)[0];

      let changed = false;

      if (elementData) {
        changed = elementData.code !== elementInnerHTML;
      } else {
        changed = elementInnerHTML !== elementDefaultLabel;
      }

      return changed;
    },
    scrollToConInput(target) {
      return new Promise((resolve) => {
        const scrollOffset = 300;
        let scrollTo =
          document.querySelector('html, body').scrollTop + target[0].getBoundingClientRect().top - scrollOffset;
        let pageEnd = document.querySelector('html').offsetHeight - document.querySelector('html').clientHeight;

        document.querySelector('html').scroll({
          top: scrollTo,
          behavior: 'smooth',
        });

        const checkIfScrollToIsFinished = setInterval(() => {
          if (
            document.querySelector('html').scrollTop === scrollTo ||
            document.querySelector('html').scrollTop === pageEnd
          ) {
            clearInterval(checkIfScrollToIsFinished);
            resolve('ok');
          }
        }, 25);
      });
    },
    focusNextInput() {
      if (this.contentFilled) {
        return;
      }

      let target = $(
        'con-section:not(.completed):not(.editing):not(.hasOptions):not([optional=true]):not(.counterparty), con-section.hasOptions > con-options > div.edited, con-section.hasOptions > con-options:not([optional=true]):not(.counterparty):not([filling-type=counterparty]), con-section.hasOptions > con-options > con-option:not(.counterparty):not([filling-type=counterparty]), con-part > con-section:not(.completed):not(.editing):not(.hasOptions):not([optional=true]):not(.counterparty):not([filling-type=counterparty]), #contract-template-content div',
      );
      if (target.length) {
        let elements = target.find(
          'con-option:visible:not([filling-type=counterparty]):not(.counterparty), con-textarea:visible:not(.edited):not(.selected):not([optional=true]):not([filling-type=counterparty]):not(.counterparty), con-date:visible:not(.edited):not(.selected):not([optional=true]):not([filling-type=counterparty]):not(.counterparty), con-number:visible:not(.edited):not(.selected):not([optional=true]):not([filling-type=counterparty]):not(.counterparty), con-part[hideable="true"]:not(.counterparty):not([filling-type=counterparty])',
        );
        if (elements.length && $(elements[0]).is('con-option') && $(elements[0]).parent().is('[optional=true]')) {
          const firstNonOptionalIndex = [...elements].findIndex((element) => {
            return !($(element).is('con-option') && $(element).parent().is('[optional=true]'));
          });

          if (-1 === firstNonOptionalIndex) {
            elements = [undefined];
          } else {
            elements = elements.slice(firstNonOptionalIndex);
          }
        }

        if (elements[0] !== undefined) {
          this.scrollToConInput(elements).then(() => {
            elements[0].click();

            this.focusConInput(elements[0]);
          });
        }
      }
    },
    toggleAutoFocusNextInput() {
      this.autoFocusNextInput = !this.autoFocusNextInput;

      if (this.autoFocusNextInput) {
        this.focusNextInput();
      }
    },
    async prefillData() {
      const status = parseInt(await ContractService.prefill(this.contract.id));

      if (200 === status) {
        return (() => {
          this.$notification.success(this.$t('general.filled'));
          this.$router.replace({ query: { registry: 'true' } });

          setTimeout(() => {
            this.$router.replace({ query: {} });
          }, 10);
        })();
      }

      if (400 === status) {
        return (() => {
          this.$notification.error(this.$t('contract.prefill.no_data'));
        })();
      }

      this.$notification.error(this.$t('general.data_not_loaded'));
    },
    async fetchContractTemplatesRegistered(contractId, contracts) {
      const templatePromises = [];

      for (let contract of contracts) {
        if ('form' !== contract.type) {
          continue;
        }

        if (
          contract?.sign_identities?.some((signIdentity) => !signIdentity.is_proposer && signIdentity.is_signed) ||
          'pending' === contract.state
        ) {
          this.templateFieldsStore.setCompletedTemplates(contract.id);
          continue;
        }

        const promise = internalApiRequest({
          method: 'GET',
          path: `api/v2/contract/${contract.id}/form/params`,
        })
          .then(async (response) => {
            let templateFields = response;

            if (contractId === contract.id) {
              this.templateFieldsStore.setTemplateFieldsData(response);
            }

            if (0 === templateFields.length || contract.current_sign_identity?.is_signed) {
              this.templateFieldsStore.setCompletedTemplates(contract.id);
            }

            await internalApiRequest({
              method: 'GET',
              path: `api/v2/contract/${contract.id}/form/answers`,
            }).then((response) => {
              if (contractId === contract.id) {
                this.templateFieldsStore.updateTemplateFieldData(response);
              }

              let templateAnswersFields = response;

              templateFields.forEach((templateField) => {
                const currentValue = templateAnswersFields?.find(
                  (answerField) => `${answerField.id}` === `${templateField.id}`,
                );

                if (currentValue) {
                  templateField.value = currentValue?.value;
                }
              });

              if (!templateFields.some((field) => !field.optional && 'counterparty' !== field.fillingType)) {
                this.templateFieldsStore.setCompletedTemplates(contract.id);
              }
            });
          })
          .catch(async () => {
            this.templateFieldsStore.setCompletedTemplates(contract.id);
          });

        templatePromises.push(promise);
      }

      return await Promise.allSettled(templatePromises);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/sass/mixins';

.contract-detail {
  &__wrapper {
    margin: -40px auto 0;
    position: relative;
    max-width: 1380px;
    width: 100%;

    @include display(960px) {
      margin-top: -30px;
    }
  }

  &__content-view {
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
  }
}

.document-pages__bottom-buttons {
  z-index: 0;
  text-align: center;
  position: sticky;
  bottom: 50px;
  padding: 0.5rem;
  background: rgb(242, 246, 252);
  box-shadow: 0px -9px 20.2px 0px rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(8px);
}
</style>

<style lang="scss">
@import '@/assets/sass/mixins';

.showImages img {
  width: 100%;
  height: auto;
}

.showImages .page {
  position: relative;
}

.controll-desc {
  font-size: 14px;
}

.content-box__todraft {
  margin-top: 180px;
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 2;
  padding: 20px;
  text-align: center;

  @include md {
    display: none;
  }
}

.edited {
  display: inline;
}

con-part {
  position: relative;
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  display: block;
  padding: 10px 0;
  margin-top: 30px;
}

con-part.show {
  padding-left: 0;
  padding-right: 0;
}

con-part.undefined {
  cursor: pointer;
  border: 1px solid #f5c2f5;
  background-color: #fbe6fb;

  con-option,
  con-section {
    border-color: transparent !important;
    background-color: transparent !important;
  }
}

con-part.editing {
  background-color: #fffad1 !important;
  border-color: #efe8aa !important;
}

con-part.show,
con-part.hide,
con-part.undefined {
  padding: 10px 30px;
}

con-options.edited con-option {
  display: none;
}

.not-selected {
  color: #6f40a7;
  text-decoration: underline;
  font-weight: bold;
}
con-options[optional='true'] .not-selected {
  color: #407ca7;
}

con-options[filling-type='counterparty'] .not-selected {
  color: #1474b9 !important;
}

con-options[filling-type='counterparty'][optional='true'] .not-selected {
  color: #5098cc !important;
}

con-options.edited-select.edited .not-selected {
  display: none;
}

con-options .counterparty {
  color: black;
  background-color: #c9eaff;
  border-color: #9aa9d1;
}

con-option {
  display: block;
  margin: 20px 0;
  padding: 30px;
  border: 1px solid #d8d8d8;
  background-color: #fbe6fb;
  border-radius: 6px;
  margin-bottom: 60px;
  position: relative;

  &:after {
    display: block;
    text-align: center;
    left: 0;
    right: 0;
    content: 'nebo'; // TODO: i18n - ???
    position: absolute;
    bottom: -40px;
    color: #000000;
    // font-weight: 300;
  }

  p {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 10px;
  }
  &:last-child:after {
    content: '';
  }
}

con-option[bind] {
  color: blue;
}

.edited-select con-option {
  position: absolute !important;
  left: -9999px;
}

.not-selected-option {
  color: #6f40a7;
  font-weight: bold;
  text-decoration: underline;
}

con-options.edited[bind*='selection'] con-option {
  display: none;
}

con-options[bind*='selection'] con-option {
  color: #6f40a7 !important;
  font-weight: bold;
  text-decoration: underline;
  position: relative;
  display: inline;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  border-radius: 0;

  &:after {
    display: none;
  }
}

con-options[bind='position'] con-option {
  display: inline;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  border-radius: 0;

  &:after {
    display: none;
  }
}

con-options[bind='position'].edited con-option {
  display: none;
}

con-options:before {
  content: 'Alternativní znění textu';
  display: block;
  text-align: center;
}

con-options[bind*='selection']:before {
  display: none;
}

con-options[bind='position']:before {
  display: none;
}

con-options.edited:before {
  display: none;
}

con-section.hasOptions.completed {
  padding: 30px;
  background-color: #e8fbf4 !important;
  border-color: #b1f0d7 !important;
}

con-section.hasOptions.counterparty {
  padding: 30px;
  background-color: #c9eaff !important;
  border-color: #9aa9d1 !important;
}

con-section.counterparty {
  background-color: #c9eaff !important;
  border-color: #9aa9d1 !important;
}

con-section.hasOptions con-options > div.edited {
  color: #6f40a7;
}

con-section.hasOptions con-options[optional='true'] > div.edited {
  color: #407ca7 !important;
}

con-section.hasOptions con-options[optional='true'] > div.edited,
div.counterparty {
  background: #c9eaff !important;
}

con-section.hasOptions con-options[bind='position'] > div.edited {
  display: inline;
  padding: 0;
  background-color: transparent;
  border: none;
}

con-section.completed con-options > div.edited {
  display: block;
  padding: 30px;
  background-color: #e8fbf4;
  border: 1px solid #b1f0d7;
  border-radius: 6px;
}

con-section.completed con-options[bind*='selection'] > div.edited {
  color: #000;
  font-weight: 600;
  padding: 0;
  display: inline-block;
  background: transparent;
  border: 0 none;
}

con-section.completed con-options[bind='position'] > div.edited {
  display: inline;
  padding: 0;
  background-color: transparent;
  border: none;
}

body con-option p {
  margin-top: 0 !important;
}

con-section {
  position: relative;
  display: block;
  padding: 30px;
  border: 1px solid #f5c2f5;
  background-color: #fbe6fb;
  border-radius: 6px;

  &.in-progress {
    background-color: #fffad1;
    border-color: #f6efb9;
  }

  &.completed {
    background-color: #e8fbf4;
    border-color: #b1f0d7;
  }

  &.editing {
    background-color: #fffad1 !important;
    border-color: #efe8aa !important;
  }
}

.content-box con-section {
  margin: 20px 0;
}

con-part con-section.hasOptions.editing {
  con-option {
    background-color: #fffad1 !important;
    border-color: #efe8aa !important;
  }
}

/* con-section.hasOptions{
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
} */

con-section.hasIdentity {
  display: block;
  position: relative;
  padding: 30px;
  border: 1px solid #f5c2f5;
  background-color: #fbe6fb;
  border-radius: 6px;
}
.content-box con-section.hasIdentity {
  margin: 20px 0;
}

con-section.hasIdentity.completed {
  background-color: #e8fbf4 !important;
  border-color: #b1f0d7 !important;
}

con-section.hasIdentity strong,
con-section.hasIdentity con-option {
  color: #6f40a7 !important;
  text-decoration: underline;
}

con-section.completed.hasIdentity strong,
con-section.completed.hasIdentity con-option {
  color: black !important;
  text-decoration: none;
}

con-text,
con-textarea,
con-number,
con-date {
  cursor: pointer;
  color: #6f40a7 !important;
  text-decoration: underline;
  font-weight: 500;

  strong {
    font-weight: 600;
  }

  p {
    color: #6f40a7 !important;
    text-decoration: underline;
    font-weight: 500;
  }

  &.selected {
    color: #9e74d0 !important;
  }
  &[optional='true'] {
    color: #a96cec !important;
  }
  &.selected[optional='true'] {
    color: #9e74d0 !important;
  }
  &[filling-type='counterparty'] {
    color: #1474b9 !important;
  }
  &[filling-type='counterparty'][optional='true'] {
    color: #5098cc !important;
  }
}

con-section.completed {
  con-text,
  con-textarea,
  con-number,
  con-date {
    cursor: pointer;
    color: black !important;
    text-decoration: none;
  }
}

con-options {
  con-text,
  con-textarea,
  con-number,
  con-date {
    cursor: pointer;
    color: grey;
    text-decoration: underline;
    font-weight: 500;
    // font-style: italic;
  }
}

con-options.edited {
  con-text,
  con-textarea,
  con-number,
  con-date {
    cursor: pointer;
    color: black;
    text-decoration: none;
    font-weight: 500;
    font-style: normal;
  }
}

con-section p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.edited {
  position: relative;
}

div.edited div.trash {
  position: absolute;
  left: -26px;
  top: 4px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border: 1px solid black;
}

#contract-template-content .ql-align-center {
  text-align: center;
}

#contract-template-content .ql-align-left {
  text-align: left;
}

#contract-template-content .ql-align-right {
  text-align: right;
}

.content-box__controll__buttons {
  margin-top: 15px;
}

.controll-title {
  font-size: 15px;
  color: #000000;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}

.content-box__card-connect-word {
  margin: 10px 0px;
  padding: 0px 25px;
}

.content-box__card {
  border: solid 1px #d4dff0;
  border-radius: 6px;
  background: #f2f6fc;
  padding: 20px 30px;
  p {
    margin-bottom: 0px;
    font-size: 16px;
  }
}
.content-box__card__button.button {
  color: #5e239e;
  padding: 0px;
  margin: 0px;
  width: auto;
  height: auto;
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  transition: 0.2s;
  &:hover {
    color: #9268be;
    transition: 0.2s;
  }
}

.content-box__data__title {
  font-weight: 500;
  text-align: center;
  margin-bottom: 12px;
}
.content-box__data__text {
  font-size: 18px;
  color: #000000;
  text-align: center;
  font-weight: 300;
}
.create-counterparty {
  text-align: center;
}
.content-box__controll-wrap {
  overflow: auto;
  padding: 20px 25px;
  background: #f2f6fc;
  //
  //@include md{
  //    padding: 15px 0;
  //top: 18% !important;
  position: fixed;
  //width: calc(100% - 20px);
  //left: 10px;
  top: 50% !important;
  left: 50%;
  max-width: 50%;
  width: 100%;
  max-height: calc(100% - 10rem);

  @include md {
    min-width: unset;
    max-width: calc(100% - 1rem);
  }

  transform: translateX(-50%) translateY(-50%);
  border-radius: 8px;
  //}
}

.trash {
  position: absolute;
  top: 0;
  left: -24px;
  margin-top: 8px;
  cursor: pointer;
  text-decoration: none;
}

.hide-part {
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer;
}

.show-part {
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer;
}

con-part.line-through,
con-part.hide {
  text-decoration: line-through;
  color: grey !important;

  h1,
  h2,
  p {
    color: grey !important;
  }
}

.button--primary-small .button__ico.button__ico--left {
  display: none;
}

.content-box__controll--boxwrap {
  //@include md{
  width: 100%;
  text-align: center;
  top: 45px !important;
  transform: translateY(0, 0);
  padding: 15px;
  position: relative;

  .save-link a {
    color: white;
  }
  //}
  form {
    //@include md{
    padding: 30px 15px;
    background: white;
    border-radius: 6px;
    //}
  }
}

.create-counterparty__box {
  width: 100%;
  display: inline-block;
  padding: 41px 92px;
  background: #ffffff;

  @include clearfix;
  @include md {
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background: transparent;
  }
}

.create-counterparty__left {
  float: left;
  width: 35%;
  text-align: left;
  @include md {
    float: none;
    width: 100%;
  }
}

.create-counterparty__right {
  float: right;
  width: 65%;

  @include md {
    float: none;
    margin-top: 20px;
    width: 100%;
  }
}

.create-counterparty__left .button {
  @include md {
    width: 100%;
  }
}

.create-counterparty__items__wrap {
  text-align: left;
  display: block;
}

.create-counterparty__last {
  padding-top: 54px;
  @include md {
    border: solid 1px #e8edf5;
    background: white;
    padding: 25px 15px;
    margin-top: 20px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    letter-spacing: -0.03px;
    text-align: center;
  }
}

.create-counterparty__items {
  padding-top: 10px;
}

.content-box {
  width: 100%;
  max-width: 1380px;
  margin: 60px auto 0;
  padding-bottom: 80px;
  position: relative;
  box-sizing: border-box;
  @include clearfix;

  background: #ffffff;
  //box-shadow: 0 12px 44px 0 rgba(0,0,0,0.10);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  a {
    font-size: 15px;
  }
  @include md {
    margin-top: 0px;
    background: transparent;
    box-shadow: none;
  }
}

.content-box__data {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  float: left;
  background-color: #fff;
  border-bottom-left-radius: 6px;
  padding: 51px 32px 63px 38px;
  border-right: solid 1px #eaf2ff;

  min-height: 515px;
  @include md {
    width: 100%;
    background: transparent;
    border: none;
    padding: 0px;
    min-height: unset;
  }
}

.content-box__data--padded {
  padding-left: 85px;
  padding-right: 85px;

  @include md {
    padding-left: 0;
    padding-right: 0;
  }
}

.content-box__data--full {
  width: 100%;
  // padding-top: 140px;
  //padding-top: 100px;
  padding-left: 15px;
  padding-right: 15px;

  @include md {
    padding-top: 0;
  }

  @include sm {
    padding-left: 0;
    padding-right: 0;
  }
}

.content-box__data__ico {
  position: absolute;
  z-index: 2;
  top: 0;
  right: -1px;

  svg {
    position: relative;
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    display: inline-block;
    width: 40px;
    height: 44px;
    background-color: #f2f6fc;
  }
  @include md {
    display: none;
  }
}

.content-box__controll {
  //width: 320px;
  float: right;
  border-bottom-right-radius: 6px;
  background: #f2f6fc;
  //min-height: 515px;

  &--hidden {
    visibility: hidden !important;
  }

  &--invisible {
    position: absolute;
    z-index: 19;
    display: block;
    width: 100%;
    min-height: 0;
    margin: auto;

    .content-box__controll-wrap {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  //&:after{
  //    content: "";
  //    position: absolute;
  //    height: 100%;
  //    background: #f1f6fd;
  //    width: 320px;
  //    top: 0px;
  //    z-index: 0;
  //    @include md{
  //        display: none;
  //    }
  //}

  //@include md{
  //    display: none;
  width: 100%;
  box-shadow: unset;
  padding: 0;
  min-height: unset;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  height: 100%;
  top: 0;
  z-index: 19;
  //}
}

.content-finish {
  text-align: center;
}

.content-finish__box {
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
  border-radius: 6px;
  border: none;
  background: white;
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 30px;
  @include md {
    box-shadow: none;
    background: transparent;
    padding: 0px;
  }

  &--with-tabs {
    @include md {
      padding-top: 20px;
    }
  }
}

.create-counterparty__left-link {
  margin-top: 12px;
  display: block;
  text-align: left;
  @include md {
    text-align: center;
  }
}

.save-link {
  position: absolute;
  z-index: 1;
  bottom: 60px;
  left: 62%;
  margin-left: 30px;
  @include md {
    width: 100%;
    margin-bottom: 30px;
    position: relative;
    left: unset;
    margin-left: 0px;
    text-align: center;
    bottom: unset;
  }
}
.link-center.save-link {
  display: block;
  left: unset;
  margin-left: unset;
  text-align: center;
  width: 38%;
  @include md {
    width: 100%;
  }
}

.content-box-wrap {
  max-width: 1380px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.content-box-button {
  position: absolute;
  right: 0px;
  top: 0px;
  .button {
    top: 2px;
  }

  @include md {
    text-align: center;
    position: absolute;
    right: 0;
    top: -7px;
    width: 30px;

    button {
      padding: 0;
    }
  }
}

.success.content-box__card {
  border-color: #b1f0d7;
  background-color: #e9fbf4;
}
.error.content-box__card {
  border-color: #f5c0c0;
  background-color: #ffe2e2;
}
.current.content-box__card {
  border-color: #efe8aa;
  background-color: #fffad1;
}

.content-finish__box-links {
  margin: 25px 0px;
}
.icon-search:before {
  transition: 0.2s;
}
.a-purple:hover .icon-search:before {
  color: #9268be;
  transition: 0.2s;
}

.edit-button-modal {
  position: absolute;
  z-index: 3;
  min-width: 250px;
  right: 0px;
  background: white;
  border-radius: 6px;
  border: solid 1px #d4dff0;
  top: 0px;
  li {
    border-top: solid 1px #d4dff0;
    padding: 15px;
    list-style-type: none;
    font-weight: 300;
    font-size: 16px;
    color: #000000;
    &:first-child {
      border-top: none;
    }
    &:last-child {
      color: #cd0000;
    }
  }
  .icon-close {
    font-size: 16px;
    position: absolute;
    right: 0px;
    top: 2px;
    padding: 15px;
  }
}

.progress-line.fixed {
  position: fixed;
  width: 100%;
  top: 60px;
  left: 0px;
}

#contract-template-content {
  color: #4a4a4a;

  @include md {
    padding: 0 15px;
  }

  p {
    margin-top: 20px;
  }

  p.centered {
    text-align: center;
  }

  h2 {
    text-align: center;
    margin: 30px 0 0 0;
  }

  h1 {
    display: none;
  }

  .table {
    width: 100%;
    margin-top: 30px;
    border-spacing: 0;
    font-size: 15px;
    color: #4a4a4a;
    letter-spacing: -0.03px;
    line-height: 1.7;
    font-weight: 300;

    tr {
      &:last-of-type {
        td {
          border-bottom-color: #000;
        }
      }

      td {
        border: 1px solid #000;
        border-right-color: transparent;
        border-bottom-color: transparent;
        padding: 5px 10px;

        &:last-of-type {
          border-right-color: #000;
        }
      }
    }
  }
}

.content-box-wrap__title {
  &--sm {
    display: none;
  }

  @include md {
    &--lg {
      display: none;
    }
    &--sm {
      display: block;
      padding: 0 30px;
    }
  }
}

.wysiwyg-editor {
  background: #fff;
  margin-top: 25px;
  border: 1px solid #ccc;
  text-align: left;

  .wysiwyg-editor-menu {
    border-bottom: 1px solid #ccc;

    button {
      border: 0 none;
      background: #fff;
      font-size: 16px;
      padding: 5px 10px;
      cursor: pointer;

      &:hover {
        color: #7346a9;
      }
    }
  }

  .wysiwyg-editor-content {
    padding: 0.5rem;

    .ProseMirror.ProseMirror-focused:focus {
      outline: unset !important;
    }

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  p.is-empty:first-child::before {
    content: attr(data-empty-text);
    float: left;
    font-weight: 300;
    color: #8e97a4;
    pointer-events: none;
    height: 0;
    font-style: italic;
  }
}

.ProseMirror {
  min-height: 150px;

  @media (max-width: 767px) {
    min-height: 50px;
    font-size: 16px;

    p {
      font-size: 16px;
    }
  }
}

.contract-creation {
  con-sign {
    margin: 0;
  }
}

.content-box__autofocus-toggle {
  top: 130px;
  margin: 30px;
  position: relative;
  z-index: 3;
  & .content-box__autofocus-toggle-description {
    position: absolute;
    top: 9px;
    right: -10px;
  }
}

.showImages-wrapper {
  .page {
    border-bottom: 3px dashed #ccc;
  }
  .page.page--last {
    border-bottom: 0;
  }
}
.progress-bar-wrapper {
  position: relative;
  max-width: 1380px;
  margin: -50px auto;

  @include display(960px) {
    margin: -40px auto -50px;
  }
}
.create-content__content-box-button {
  position: absolute;
  top: -10px;
  right: 0;
  @include md {
    position: relative;
    text-align: right;
    width: 100%;
    top: -20px;
    display: flex;
  }
  .button {
    right: 0;
  }
}

.fill-from-registry-button {
  text-align: center;
  color: black;
  margin-top: 20px;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.sign-placement__header-move-top {
  @include md {
    top: -100px;
  }
}
.drag-out {
  opacity: 1;
}
.sign-placement__preview {
  opacity: 1;
  width: 100%;
  * {
    opacity: 1;
  }
}
.sign-placement__preview__no_header {
  .sign-placement__date {
    visibility: hidden;
  }
}
</style>
